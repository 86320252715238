const CustomTextField = ({
  isForm,
  error,
  label,
  disabled,
  className = "",
  textboxSwitch = false,
  ...inputProps
}) => {
  return (
    <>
      <div
        className={`${
          textboxSwitch ? "normal_content" : "vertical_content"
        } w-100 mb-4 d-flex align-items-start`}
      >
        <label className="form-label mt-3 main_label text-nowrap">
          {label}
        </label>
        <div className="mt-0 single_input_main_div">
          <input
            className={"form-control"}
            {...inputProps}
            disabled={disabled}
          />
          <div
            className={error && "text-danger mt-1 input_main_div float-right"}
          >
            {!!error && <span className="error_msg mt-1">{error}</span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomTextField;
