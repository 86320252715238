import axios from "axios";

import Cookies from "./cookies";
import LocalStorage from "./localStorage";
// import store from "../store";
// import { logout } from "../modules/Auth/slice/AuthSlice";
const BASE_URL = "https://api.milliardcalculator.com/";
// const BASE_URL = "http://192.168.1.96:3001";
// const BASE_URL = "http://192.168.1.220:3001";

export const MILLIARD_ADMIN_API_URL = `${BASE_URL}/api`;

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Bearer token on every request
    const token = LocalStorage.get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response.
    if (response?.data?.token) {
      Cookies.set("token", response.data.token);
    }
    return response;
  };

  _responseErr = (err) => {
    // const navigate = useNavigate();
    //if error comes than handle
    if (err?.response?.status === 401) {
      localStorage.clear();
      window.open("/", "_self");
      // navigate(`/${routesConstants.LOGIN}`);
      // store.dispatch(logout());

      return Promise.reject(err);
    }
    return Promise.reject(err);
  };
}

const axiosMilliardAdmin = new Axios(MILLIARD_ADMIN_API_URL).axios;

export { axiosMilliardAdmin };
