import {
  SET_AUTH,
  SET_PAGE_ERROR,
  SET_PERMISSIONS,
  SET_PERMISSIONS_ERROR,
  SET_PERMISSIONS_SUCCESS,
  SET_USER,
  SET_VERIFY_LINK,
  SET_VERIFY_LINK_SUCCESS,
} from "./actionTypes";

const initialState = {
  isAuth: localStorage.getItem("token") || false,
  user: null,
  pageError: "",
  permissions: null,
  userLink: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        loading: payload,
      };
    case SET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: payload,
      };
    case SET_PERMISSIONS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_PAGE_ERROR:
      return { ...state, pageError: payload };
    case SET_VERIFY_LINK:
      return {
        ...state,
        loading: payload,
      };
    case SET_VERIFY_LINK_SUCCESS:
      return {
        ...state,
        userLink: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
