import React from "react";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import SmallLogo from "../assets/images/SmallLogo.png";
import SiteLogo from "../assets/images/SiteLogo.png";
import Cookies from "../services/cookies";
import {routesConstants} from "../routes/routesConstants";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {handleSetAuth} from "../store/actions";
import {axiosMilliardAdmin} from "services/api";
import {LOGOUT} from "constants/url";

const Header = () => {
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const username = localStorage.getItem("username");
    const headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
    };

    const handleLogout = async () => {
        try {
            const response = await axiosMilliardAdmin.put(
                LOGOUT,
                {
                    access_token: JSON.parse(token),
                },
                {headers}
            );
            if (response) {
                Cookies.clear();
                localStorage.clear();
                dispatch(handleSetAuth(false));
                toast.success(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <Link className="navbar-brand brand-logo text-white fw-bold" to={routesConstants?.products}>
                    <img src={SiteLogo} alt="logo" />
                </Link>
                <Link className="navbar-brand brand-logo-mini" to="/">
                    <img src={SmallLogo} alt="logo" />
                </Link>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                <button
                    className="navbar-toggler align-self-center"
                    type="button"
                    onClick={() => document.body.classList.toggle("sidebar-icon-only")}
                >
                    <span className="mdi mdi-menu"></span>
                </button>
                <ul className="navbar-nav navbar-nav-right">
                    <span>{username?.replaceAll('"', "")}</span>
                    <li className="nav-item nav-profile">
                        <Dropdown>
                            <Dropdown.Toggle className="nav-link align-items-center justify-content-center">
                                <div className="nav-profile-img">
                                    <i className="fa-solid fa-user" style={{color: "black"}}></i>
                                </div>
                                <div className="nav-profile-text"></div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="navbar-dropdown">
                                <Dropdown.Item onClick={handleLogout} style={{color: "black"}}>
                                    <i className="mdi mdi-logout me-2" style={{color: "black"}}></i>
                                    Signout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>
                <button
                    className="navbar-toggler navbar-toggler-right d-none align-self-center"
                    type="button"
                    onClick={() => document.querySelector(".sidebar-offcanvas").classList.toggle("active")}
                >
                    <span className="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    );
};

export default Header;
