export const sizePerPageList = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  {
    label: 15,
    value: 15,
  },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
