import {
  SET_GROUP_DETAIL,
  SET_GROUP_DETAIL_SUCCESS,
  SET_MASTER_COST_GROUP_TYPES,
  SET_MASTER_COST_GROUP_TYPES_SUCCESS,
  SET_OVERHEAD_COST_GROUP,
  SET_OVERHEAD_COST_GROUP_SUCCESS,
  SET_OVERHEAD_PAGE,
  SET_OVERHEAD_SIZE_PER_PAGE,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  overheadCostGroups: null,
  masterCostTypes: null,
  singleCostGroup: null,
  page: 1,
  sizePerPage: 10,
};

const overheadCostReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MASTER_COST_GROUP_TYPES:
      return {
        ...state,
        loading: payload,
      };
    case SET_MASTER_COST_GROUP_TYPES_SUCCESS:
      return {
        ...state,
        masterCostTypes: payload,
      };
    case SET_OVERHEAD_COST_GROUP:
      return {
        ...state,
        loading: payload,
      };
    case SET_OVERHEAD_COST_GROUP_SUCCESS:
      return {
        ...state,
        overheadCostGroups: payload,
      };
    case SET_GROUP_DETAIL:
      return {
        ...state,
        loading: payload,
      };
    case SET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        singleCostGroup: payload,
      };
    case SET_OVERHEAD_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_OVERHEAD_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    default:
      return state;
  }
};

export default overheadCostReducer;
