import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "components/common/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants/Table";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";
import deleteIcon from "assets/icons/delete.png";
import editIcon from "assets/icons/edit.png";
import {
  deleteLandingCosts,
  fetchLandingCosts,
  setLandingCostsPage,
  setLandingCostsSizePerPage,
} from "../store/actions";
import { getHiddenRowKeys } from "utils/getHiddenRowKeys";
import { FaSort } from "react-icons/fa";
import { Oval } from "react-loader-spinner";

const LandingCostTable = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [landingCostId, setLandingCostId] = useState();
  const [landingCostData, setLandingCostData] = useState();

  const { landingCosts, sizePerPage, page, loading } = useSelector(
    (state) => state.landingCostReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "left",
      headerStyle: {
        width: "15%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns me-2"
              onClick={() => {
                setLandingCostId(row.landed_cost_id);
                navigate(
                  `/${routesConstants.LANDING_COST}/edit/${row.landed_cost_id}`
                );
              }}
            >
              <img src={editIcon} alt="edit" title="Edit" />
            </div>

            <div
              className="actionBtns"
              onClick={() => {
                setIsOpenDelete(true);
                setLandingCostId(row.landed_cost_id);
              }}
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        );
      },
    },

    {
      dataField: "landed_cost_name",
      headerAlign: "left",
      text: "Landed Cost Group",
      sort: true,
    },
    {
      dataField: "source_country",
      headerAlign: "left",
      text: "Source Country",
      sort: true,
    },
    {
      dataField: "destination_country",
      headerAlign: "left",
      text: "Destination Country",
      sort: true,
    },
    {
      dataField: "sea_freight",
      headerAlign: "left",
      text: "Sea Freight",
      sort: true,
      formatter: (cell, row) => {
        return <span>{`$${cell}`}</span>;
      },
    },
    {
      dataField: "duties",
      headerAlign: "left",
      text: "Duties",
      sort: true,
      formatter: (cell, row) => {
        return <span>{`${cell}% of Factory Cost`}</span>;
      },
    },
    {
      dataField: "tarif",
      headerAlign: "left",
      text: "Tarif",
      sort: true,
      formatter: (cell, row) => {
        return <span>{`${cell}% of Factory Cost`}</span>;
      },
    },
    {
      dataField: "drayage",
      headerAlign: "left",
      text: "Drayage",
      sort: true,
      formatter: (cell, row) => {
        return <span>{`$${cell}`}</span>;
      },
    },
    {
      dataField: "unloading",
      headerAlign: "left",
      text: "Unloading",
      sort: true,
      formatter: (cell, row) => {
        return <span>{`$${cell}`}</span>;
      },
    },
  ]);

  /* for add sorting icons manually */
  const columnsData = columns?.map((col) => ({
    ...col,
    ...(col.hasOwnProperty("sort")
      ? {
          sortCaret: (order) => {
            return (
              <span className="ms-2 sort-arrows cursor-pointer">
                <FaSort />
              </span>
            );
          },
        }
      : {}),
  }));

  /* navigate add landing cost route */
  const handleAddLandingCost = () => {
    navigate(`/${routesConstants.ADD_LANDING_COST}`);
  };

  const handleClose = () => {
    setIsOpenDelete(false);
  };

  /* get list of landed costs */
  useEffect(() => {
    dispatch(
      fetchLandingCosts(
        {
          search: "",
        },
        (res) => {
          if (res) {
            setLandingCostData(res);
          }
        }
      )
    );
  }, [dispatch]);

  /* change value of page when page change */
  const handlePageChange = (value) => {
    dispatch(setLandingCostsPage(value));
  };

  /* change value of sizePerPage when size per page select from dropdown */
  const handleSizePerPageChange = ({ value }) => {
    dispatch(setLandingCostsSizePerPage(value));
    dispatch(setLandingCostsPage(1));
  };

  /* delete landed cost by landed_cost_id */
  const handleDeleteLandingCosts = () => {
    dispatch(
      deleteLandingCosts(
        {
          landed_cost_id: landingCostId,
        },
        (res) => {
          if (res) {
            dispatch(setLandingCostsPage(1));
            setIsOpenDelete(false);
            dispatch(
              fetchLandingCosts(
                {
                  search: "",
                },
                (res) => {
                  if (res) {
                    setLandingCostData(res);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const { hiddenRows } = getHiddenRowKeys({
    page,
    sizePerPage,
    list: landingCosts,
    keyField: "landed_cost_id",
  });

  /* client side search by landed cost name */
  const handleFilter = (search) => {
    if (search === "") setLandingCostData(landingCosts);
    else {
      let array = [];
      array = landingCosts?.filter(
        (f) =>
          f.landed_cost_name?.toLowerCase()?.search(search.toLowerCase()) !== -1
      );
      setLandingCostData(array);
    }
  };

  return (
    <div>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Landed Costs</h4>
          <button className="btnAddAdmin" onClick={handleAddLandingCost}>
            Add
          </button>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <input
            type="search"
            className="searchInput mt-3"
            placeholder="Search"
            onChange={(e) => {
              handleFilter(e.target.value);
            }}
          />
        </div>

        <div className="mb-2 d-flex flex-column ">
          <div className="flex-grow-1 ">
            {loading ? (
              <div className="loader-spin">
                <Oval
                  height={40}
                  width={40}
                  color="#464243"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#464243"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-responsive main-table">
                <BootstrapTable
                  noDataIndication={() => (
                    <span className="text-center margin_left_45">
                      No Data Found
                    </span>
                  )}
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"landed_cost_id"}
                  hiddenRows={hiddenRows}
                  data={landingCostData?.length > 0 ? landingCostData : []}
                  columns={columnsData}
                  hover
                  classes={`table table-hover`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          {landingCostData?.length > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+page}
                sizePerPage={sizePerPage}
                totalRecords={landingCostData.length}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpenDelete}
        handleClose={handleClose}
        cb={handleDeleteLandingCosts}
        btnText={"Delete"}
        title={isOpenDelete}
        subTitle={"Are you sure want to delete this Landed Cost?"}
      />
    </div>
  );
};

export default LandingCostTable;
