import React from "react";
import OverheadCostGroupTable from "../components/OverheadCostGroupTable";

const OverheadCostGroup = () => {
  return (
    <div>
      <OverheadCostGroupTable />
    </div>
  );
};

export default OverheadCostGroup;
