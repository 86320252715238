import React, { Fragment } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const NoDataIndiCation = ({ loading }) => {
  return loading ? (
    <div className="d-flex justify-content-center align-items-center">
      <span className="text-muted">Please Wait While Data Is Loading...</span>
    </div>
  ) : (
    <div className="d-flex justify-content-center align-items-center">
      <span className="text-muted">No Data Available!</span>
    </div>
  );
};

const CustomBootstrapTable = ({
  keyField,
  data,
  columns,
  onTableChange,
  selectRow,
  defaultSorted,
  children,
  bordered = false,
  light = true,
  remote = true,
  loading,
  withExport = false,
  withExportFooter = false,
  exportData,
  cavProps,
  baseProps,
  ...rest
}) => {
  const columnsData = columns?.map((col) => ({
    ...col,
    ...(col.hasOwnProperty("sort")
      ? {
          sortCaret: (order) => {
            return (
              <span className="ms-2 sort-arrows cursor-pointer">
                {/* {!!order ? (
                  order === "desc" ? (
                    <FaSortDown />
                  ) : order === "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSort />
                  )
                ) : (
                  <>
                    <FaSort />
                  </>
                )} */}
                <FaSort />
              </span>
            );
          },
        }
      : {}),
  }));

  return (
    <Fragment>
      <div className="mb-2 d-flex flex-column ">
        <div className="flex-grow-1">
          <div className="table-responsive main-table">
            <BootstrapTable
              {...rest}
              {...baseProps}
              remote={remote}
              responsive
              bordered={false}
              striped={false}
              keyField={keyField}
              data={!loading ? data : []}
              columns={columnsData}
              onTableChange={onTableChange}
              selectRow={selectRow}
              hover
              defaultSorted={defaultSorted}
              classes={`table table-hover`}
              noDataIndication={() => <NoDataIndiCation loading={loading} />}
              loading={loading}
            />
          </div>
        </div>
        {children}
      </div>
    </Fragment>
  );
};

const Table = ({ children, withPagination = true, tableTitle, ...rest }) => {
  const ExportCSVButton = (props) => {
    const handleClick = () => {
      if (rest?.handleCustomExport) {
        rest.handleCustomExport((cb) => {
          if (cb) {
            props.onExport();
          }
        });
      } else {
        props.onExport();
      }
    };
    return (
      <div>
        <button
          className={`react-bs-table-csv-btn btn btn-default btn btn-export ms-2 ${
            !!rest.handleCustomExportLoading &&
            rest?.handleCustomExportLoading &&
            "disabled"
          }`}
          onClick={handleClick}
        >
          {!!rest.handleCustomExportLoading && rest?.handleCustomExportLoading
            ? "EXPORTING.."
            : "EXPORT"}
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 mb-3">
        <h4 className="m-0">{tableTitle}</h4>
        {children}
      </div>
      <ToolkitProvider
        data={rest.exportData}
        columns={rest.columns}
        keyField={rest.keyField}
        exportCSV={{
          ignoreFooter: rest.withExportFooter ? false : true,
        }}
      >
        {(props) => (
          <>
            {withPagination ? (
              <PaginationTable {...rest} {...props} />
            ) : (
              <CustomBootstrapTable {...rest} {...props}>
                <div className="d-flex mt-2">
                  <div className="d-flex align-items-center">
                    {rest.withExport && (
                      <ExportCSVButton
                        {...props.csvProps}
                        {...rest}
                        className="btn btn-export"
                        disabled={rest.loading || !!!rest.data.length}
                      >
                        EXPORT
                      </ExportCSVButton>
                    )}
                  </div>
                </div>
              </CustomBootstrapTable>
            )}
          </>
        )}
      </ToolkitProvider>
    </>
  );
};

const pageListRenderer = ({ pages, onPageChange }) => {
  const pageWithoutIndication = pages.filter((p) => typeof p.page !== "string");
  return (
    <div>
      {pageWithoutIndication?.map((p) => (
        <button
          className="btn btn-success"
          onClick={() => onPageChange(p.page)}
        >
          {p.page}
        </button>
      ))}
    </div>
  );
};

const options = (pageOptions) => {
  return {
    ...pageOptions,
    pageListRenderer,
  };
};

const withPagination =
  (Table) =>
  ({ pageOptions, ...rest }) => {
    const ExportCSVButton = (props) => {
      const handleClick = () => {
        if (rest?.handleCustomExport) {
          rest.handleCustomExport((cb) => {
            if (cb) {
              props.onExport();
            }
          });
        } else {
          props.onExport();
        }
      };
      return (
        <div>
          <button
            className={`react-bs-table-csv-btn btn btn-default btn btn-export ms-2 ${
              !!rest.handleCustomExportLoading &&
              rest?.handleCustomExportLoading &&
              "disabled"
            }`}
            onClick={handleClick}
          >
            {!!rest.handleCustomExportLoading && rest?.handleCustomExportLoading
              ? "EXPORTING.."
              : "EXPORT"}
          </button>
        </div>
      );
    };

    return (
      <PaginationProvider pagination={paginationFactory(options(pageOptions))}>
        {({ paginationProps, paginationTableProps }) => (
          <Table {...rest} {...paginationTableProps}>
            {!!rest.data.length && (
              <>
                <div className="d-flex mt-3">
                  <div className="d-flex align-items-center">
                    {/* <>
                      <span className="me-2 d-none d-md-block ">
                        Records Per Page:
                      </span>
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                        btnContextual={"btn btn-gradient-primary h-42"}
                      />
                    </> */}

                    {rest.withExport && (
                      <ExportCSVButton
                        {...rest.csvProps}
                        className="btn btn-inverse-primary ms-2"
                        disabled={rest.loading || !!!rest?.exportData.length}
                      >
                        EXPORT
                      </ExportCSVButton>
                    )}
                  </div>
                </div>
              </>
            )}
          </Table>
        )}
      </PaginationProvider>
    );
  };

const PaginationTable = withPagination(CustomBootstrapTable);

const CustomTable = ({ withCard = false, withPagination = true, ...rest }) => {
  return withCard ? (
    <div className="card-body p-0">
      <Table withPagination={withPagination} {...rest} />
    </div>
  ) : (
    <Table {...rest} />
  );
};
export default CustomTable;
