export const SET_OVERHEAD_COST_GROUP = "SET_OVERHEAD_COST_GROUP";
export const SET_OVERHEAD_COST_GROUP_SUCCESS =
  "SET_OVERHEAD_COST_GROUP_SUCCESS";

export const SET_MASTER_COST_GROUP_TYPES = "SET_MASTER_COST_GROUP_TYPES";
export const SET_MASTER_COST_GROUP_TYPES_SUCCESS =
  "SET_MASTER_COST_GROUP_TYPES_SUCCESS";

export const SET_GROUP_DETAIL = "SET_GROUP_DETAIL";
export const SET_GROUP_DETAIL_SUCCESS = "SET_GROUP_DETAIL_SUCCESS";

export const SET_OVERHEAD_PAGE = "SET_OVERHEAD_PAGE";
export const SET_OVERHEAD_SIZE_PER_PAGE = "SET_OVERHEAD_SIZE_PER_PAGE";
