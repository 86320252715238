import {
  SET_COUNTRIES,
  SET_COUNTRIES_SUCCESS,
  SET_COUNTRIES_ERROR,
  SET_LANDING_COSTS_PAGE,
  SET_LANDING_COSTS_SIZE_PER_PAGE,
  SET_LANDING_COSTS_COUNT,
  SET_LANDING_COSTS,
  SET_LANDING_COSTS_SUCCESS,
  SET_LANDING_COSTS_ERROR,
} from "./actionTypes";

const initialState = {
  countries: null,
  loading: false,
  error: "",
  landingCosts: null,
  page: 1,
  sizePerPage: 10,
  count: 0,
};

const landingCostReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COUNTRIES:
      return {
        ...state,
        loading: payload,
      };
    case SET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
      };
    case SET_COUNTRIES_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_LANDING_COSTS:
      return {
        ...state,
        loading: payload,
      };
    case SET_LANDING_COSTS_SUCCESS:
      return {
        ...state,
        landingCosts: payload,
      };
    case SET_LANDING_COSTS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_LANDING_COSTS_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_LANDING_COSTS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_LANDING_COSTS_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
};

export default landingCostReducer;
