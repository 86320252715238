import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModel = ({
  title = "Delete Confirmation",
  isOpen,
  cb,
  handleClose,
  btnText = "Delete",
  name,
  subTitle = "Are you sure want to delete?",
}) => {
  return (
    <Modal show={isOpen} className="fade delete_popup">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <Modal.Header className="lex-column d-inline-block">
            <Modal.Title className="icon-box">
              <i className="fa-sharp fa-solid fa-circle-exclamation"></i>
            </Modal.Title>
            <h4 className="modal-title w-100">{title}</h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <span>{subTitle}</span>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button type="button" onClick={handleClose}>
              {"Cancel"}
            </button>
            <button type="button" className="btn btn-danger" onClick={cb}>
              {btnText}
            </button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModel;
