import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import ConfirmationModal from "components/common/ConfirmationModal";
import { setGlobalError } from "store/actions";
import { routesConstants } from "routes/routesConstants";
import LocalStorage from "services/localStorage";
import Cookies from "services/cookies";

const CheckPagePermission = () => {
  const { pageError, permissions } = useSelector((state) => state.Auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_role = LocalStorage.get("user_role");

  const handleNavigation = () => {
    if (+permissions?.is_users === 1) {
      navigate(`/${routesConstants.USERS}`);
    } else if (+permissions?.is_amazon_fees === 1) {
      navigate(`/${routesConstants.AMAZON_FEES}`);
    } else if (+permissions?.is_products === 1) {
      navigate(`/${routesConstants.PRODUCTS}`);
    } else if (+permissions?.is_landing_cost === 1) {
      navigate(`/${routesConstants.LANDING_COST}`);
    } else if (+permissions?.is_websites === 1) {
      navigate(`/${routesConstants.WEBSITES}`);
    } else if (+permissions?.is_overhead_cost_group === 1) {
      navigate(`/${routesConstants.OVERHEAD_COST_GROUP}`);
    } else if (+user_role === 1) {
      navigate(`/${routesConstants.ADMINS}`);
    } else {
      navigate("/");
      Cookies.clear();
      localStorage.clear();
    }
    dispatch(setGlobalError(""));
  };
  return pageError &&
    pageError?.response?.data?.message ===
      "You don't have the permission for this action." ? (
    <ConfirmationModal
      type="warning"
      btnText="Ok"
      title="Your access has been revoked"
      subTitle={pageError.response.data.message || pageError.message}
      isOpen={true}
      handleClose={handleNavigation}
      cb={handleNavigation}
      modalProps={{
        backdrop: "static",
        modalClassName: "pe-none",
      }}
    />
  ) : (
    <Outlet />
  );
};

export default CheckPagePermission;
