import {
  DELETE_PRODUCT,
  GET_PRODUCT_DETAILS,
  LIST_PRODUCTS,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { setGlobalError } from "store/actions";
import {
  SET_PAGE,
  SET_PRODUCTS,
  SET_PRODUCTS_ERRORS,
  SET_PRODUCTS_SUCCESS,
  SET_SIZE_PER_PAGE,
  SET_TOTAL_COUNT,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_PRODUCT_DETAILS,
  SET_PRODUCT_DETAILS_SUCCESS,
} from "./actionTypes";

export const fetchProducts = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setProducts(true));
      const res = await axiosMilliardAdmin.post(LIST_PRODUCTS, payload);
      if (res?.data?.data) {
        dispatch(setProductsSuccess(res.data.data.rows));
        dispatch(setTotalCount(res.data.data.count));
      }
      dispatch(setProducts(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
        dispatch(setProducts(false));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setProducts(false));
      }
    }
  };
};

export const deleteProduct = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosMilliardAdmin.post(`/${DELETE_PRODUCT}`, payload);
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
      }
    }
  };
};

export const fetchProductDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setProductDetails(true));
      const res = await axiosMilliardAdmin.post(GET_PRODUCT_DETAILS, payload);
      if (res?.data?.data) {
        dispatch(setProductDetailSuccess(res.data.data));
      }
      dispatch(setProductDetails(false));
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
      dispatch(setProductDetails(false));
    }
  };
};

export const setProducts = (data) => ({
  type: SET_PRODUCTS,
  payload: data,
});

export const setProductsSuccess = (data) => ({
  type: SET_PRODUCTS_SUCCESS,
  payload: data,
});

export const setProductsErrors = (data) => ({
  type: SET_PRODUCTS_ERRORS,
  payload: data,
});

export const setPage = (data) => ({
  type: SET_PAGE,
  payload: data,
});

export const setSizePerPage = (data) => ({
  type: SET_SIZE_PER_PAGE,
  payload: data,
});

export const setTotalCount = (data) => ({
  type: SET_TOTAL_COUNT,
  payload: data,
});

export const setSortField = (data) => ({
  type: SET_SORT_FIELD,
  payload: data,
});

export const setSortOrder = (data) => ({
  type: SET_SORT_ORDER,
  payload: data,
});

export const setProductDetails = (data) => ({
  type: SET_PRODUCT_DETAILS,
  payload: data,
});

export const setProductDetailSuccess = (data) => ({
  type: SET_PRODUCT_DETAILS_SUCCESS,
  payload: data,
});
