import {
  SET_PRODUCT_FEES,
  SET_PRODUCT_FEES_ERROR,
  SET_PRODUCT_FEES_SUCCESS,
} from "./actionTypes";

const initialState = {
  feesDetails: null,
  loading: false,
  error: "",
};

const feesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCT_FEES:
      return {
        ...state,
        loading: payload,
      };
    case SET_PRODUCT_FEES_SUCCESS:
      return {
        ...state,
        feesDetails: payload,
      };
    case SET_PRODUCT_FEES_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default feesReducer;
