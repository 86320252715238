import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  fetchUsers,
  setUserPage,
  setUserSizePerPage,
} from "../store/actions";

import Pagination from "components/common/Pagination";
import useDebounced from "hooks/useDebounced";
import BootstrapTable from "react-bootstrap-table-next";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants/Table";
import deleteIcon from "assets/icons/delete.png";
import { Oval } from "react-loader-spinner";

const UserTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState();
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounced(search);
  const dispatch = useDispatch();

  const { usersList, pageNo, sizePerPage, count, loading } = useSelector(
    (state) => state.userReducer
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns"
              onClick={() => {
                setIsOpen(true);
                setUserId(row.user_id);
              }}
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Name",
      headerAlign: "left",
      formatter: (cell, row) => {
        return <span>{`${row.first_name} ${row.last_name} `}</span>;
      },
    },
    {
      dataField: "email",
      headerAlign: "left",
      text: "Email",
    },
    {
      dataField: "total_product",
      headerAlign: "left",
      text: "Total Product",
    },
    {
      dataField: "total_selling_plans",
      headerAlign: "left",
      text: "Total Plans",
    },
  ]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageNo: pageNo,
        pageRecords: sizePerPage,
        search: debounceSearch,
      })
    );
  }, [dispatch, pageNo, debounceSearch, sizePerPage]);

  const handleDeleteUser = () => {
    dispatch(
      deleteUser(userId, (res) => {
        if (res) {
          setIsOpen(false);
          dispatch(setUserPage(1));
          dispatch(
            fetchUsers({
              pageNo: pageNo,
              pageRecords: sizePerPage,
              search: debounceSearch,
            })
          );
        }
      })
    );
  };

  const handlePageChange = (page) => {
    dispatch(setUserPage(page));
    dispatch(setUserSizePerPage(sizePerPage));
  };

  const onTableChange = (page) => {
    dispatch(setUserPage(page));
    dispatch(setUserSizePerPage(sizePerPage));
  };

  const NoDataIndiCation = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span className="text-muted">No User Found!</span>
      </div>
    );
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setUserSizePerPage(value));
    dispatch(setUserPage(1));
  };

  return (
    <div>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Users</h4>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <input
            type="search"
            className="searchInput"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
              dispatch(setUserSizePerPage(sizePerPage));
              dispatch(setUserPage(1));
            }}
          />
        </div>
        <div className="mb-2 d-flex flex-column ">
          <div className="flex-grow-1 loader-spin">
            {loading ? (
              <div className="loader-spin">
                {" "}
                <Oval
                  height={40}
                  width={40}
                  color="#464243"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#464243"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-responsive main-table">
                <BootstrapTable
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"user_id"}
                  data={usersList ? usersList : []}
                  columns={columns}
                  onTableChange={onTableChange}
                  hover
                  classes={`table table-hover`}
                  noDataIndication={() => (
                    <NoDataIndiCation loading={loading} />
                  )}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          {count > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+pageNo}
                sizePerPage={sizePerPage}
                totalRecords={count}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        cb={handleDeleteUser}
      />
    </div>
  );
};

export default UserTable;
