import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LocalStorage from "services/localStorage";
import { routesConstants } from "../routes/routesConstants";
import productIcon from "assets/icons/product.png";
import landingCostIcon from "assets/icons/container.png";
import websitesIcon from "assets/icons/world-wide-web.png";
import adminIcon from "assets/icons/setting.png";
import overheadIcon from "assets/icons/warehouse.png";

const Sidebar = () => {
  const location = useLocation();
  const { permissions } = useSelector((state) => state.Auth);

  const user_role = LocalStorage.get("user_role");
  const isPathActive = (path) => {
    return location.pathname?.replace("/", "") === path;
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {+permissions?.is_users === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants?.USERS}`)
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants.USERS}`}>
                <svg
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  style={{
                    height: "26px",
                    width: "26px",
                    marginRight: "20px",
                  }}
                  viewBox="0 0 512 512"
                  className=""
                >
                  <g>
                    <circle cx="256" cy="128" r="128" />
                    <path d="M256,298.667c-105.99,0.118-191.882,86.01-192,192C64,502.449,73.551,512,85.333,512h341.333   c11.782,0,21.333-9.551,21.333-21.333C447.882,384.677,361.99,298.784,256,298.667z" />
                  </g>
                </svg>
                <span className="menu-title">Users</span>
              </Link>
            </li>
          )}
          {+permissions?.is_amazon_fees === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants?.AMAZON_FEES}`)
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants?.AMAZON_FEES}`}>
                <svg
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  style={{
                    height: "26px",
                    width: "26px",
                    marginRight: "20px",
                  }}
                  viewBox="0 0 512 512"
                  className=""
                >
                  <g>
                    <path
                      d="m391 250-120 40 120 40 120-40zM1 290l120 40 120-40-120-40zM260.743.77a15.005 15.005 0 0 0-9.487 0L136 39.189l120 40 120-40zM136 512l105-35V321.623l-105 35zM1 466.189a14.998 14.998 0 0 0 10.257 14.23L106 512V356.623l-105-35zM271 477l105 35V356.623l-105-35zM406 512l94.743-31.581A15 15 0 0 0 511 466.189V321.623l-105 35zM136 229l105 35V105.812L136 70.811zM376 229V70.811l-105 35.001V264z"
                      fill="#ffffff"
                      data-original="#000000"
                      opacity="1"
                      className=""
                    ></path>
                  </g>
                </svg>
                <span className="menu-title">Amazon Fees</span>
              </Link>
            </li>
          )}

          {+permissions?.is_products === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants?.PRODUCTS}`) &&
                !location.pathname?.startsWith("/product") &&
                !location.pathname?.startsWith("/selling-plans")
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants?.PRODUCTS}`}>
                {/* <svg
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  style={{
                    height: "26px",
                    width: "26px",
                    marginRight: "20px",
                  }}
                  viewBox="0 0 512 512"
                  className=""
                >
                  <g>
                    <path
                      d="m391 250-120 40 120 40 120-40zM1 290l120 40 120-40-120-40zM260.743.77a15.005 15.005 0 0 0-9.487 0L136 39.189l120 40 120-40zM136 512l105-35V321.623l-105 35zM1 466.189a14.998 14.998 0 0 0 10.257 14.23L106 512V356.623l-105-35zM271 477l105 35V356.623l-105-35zM406 512l94.743-31.581A15 15 0 0 0 511 466.189V321.623l-105 35zM136 229l105 35V105.812L136 70.811zM376 229V70.811l-105 35.001V264z"
                      fill="#ffffff"
                      data-original="#000000"
                      opacity="1"
                      className=""
                    ></path>
                  </g>
                </svg> */}
                <img src={productIcon} alt="product" className="sideIcon" />
                <span className="menu-title">Products</span>
              </Link>
            </li>
          )}
          {+permissions?.is_landing_cost === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants.LANDING_COST}`) &&
                !location.pathname?.startsWith("/landed-cost")
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants.LANDING_COST}`}>
                {/* <svg
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  style={{
                    height: "26px",
                    width: "26px",
                    marginRight: "20px",
                  }}
                  viewBox="0 0 512 512"
                  className=""
                >
                  <g>
                    <path
                      d="m391 250-120 40 120 40 120-40zM1 290l120 40 120-40-120-40zM260.743.77a15.005 15.005 0 0 0-9.487 0L136 39.189l120 40 120-40zM136 512l105-35V321.623l-105 35zM1 466.189a14.998 14.998 0 0 0 10.257 14.23L106 512V356.623l-105-35zM271 477l105 35V356.623l-105-35zM406 512l94.743-31.581A15 15 0 0 0 511 466.189V321.623l-105 35zM136 229l105 35V105.812L136 70.811zM376 229V70.811l-105 35.001V264z"
                      fill="#ffffff"
                      data-original="#000000"
                      opacity="1"
                      className=""
                    ></path>
                  </g>
                </svg> */}
                <img src={landingCostIcon} alt="Landing" className="sideIcon" />
                <span className="menu-title">Landed Costs</span>
              </Link>
            </li>
          )}
          {+permissions?.is_websites === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants.WEBSITES}`) &&
                !location.pathname?.startsWith("/websites")
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants.WEBSITES}`}>
                <img src={websitesIcon} alt="website" className="sideIcon" />
                <span className="menu-title">Websites</span>
              </Link>
            </li>
          )}
          {+permissions?.is_overhead_cost_group === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants.OVERHEAD_COST_GROUP}`) &&
                !location.pathname?.startsWith("/overhead-cost-group")
                  ? "nav-item active "
                  : "nav-item bgSide"
              }
            >
              <Link
                className="nav-link"
                to={`${routesConstants.OVERHEAD_COST_GROUP}`}
              >
                <img src={overheadIcon} alt="Overhead" className="sideIcon" />
                <span className="menu-title">Overhead Cost Groups</span>
              </Link>
            </li>
          )}
          {+user_role === 1 && (
            <li
              className={
                !isPathActive(`${routesConstants.ADMINS}`) &&
                !location.pathname?.startsWith("/admin")
                  ? "nav-item active"
                  : "nav-item bgSide"
              }
            >
              <Link className="nav-link" to={`${routesConstants.ADMINS}`}>
                {/* <svg
                  version="1.1"
                  width="512"
                  height="512"
                  x="0"
                  y="0"
                  style={{
                    height: "26px",
                    width: "26px",
                    marginRight: "20px",
                  }}
                  viewBox="0 0 512 512"
                  className=""
                >
                  <g>
                    <path
                      d="m391 250-120 40 120 40 120-40zM1 290l120 40 120-40-120-40zM260.743.77a15.005 15.005 0 0 0-9.487 0L136 39.189l120 40 120-40zM136 512l105-35V321.623l-105 35zM1 466.189a14.998 14.998 0 0 0 10.257 14.23L106 512V356.623l-105-35zM271 477l105 35V356.623l-105-35zM406 512l94.743-31.581A15 15 0 0 0 511 466.189V321.623l-105 35zM136 229l105 35V105.812L136 70.811zM376 229V70.811l-105 35.001V264z"
                      fill="#ffffff"
                      data-original="#000000"
                      opacity="1"
                      className=""
                    ></path>
                  </g>
                </svg> */}
                <img src={adminIcon} alt="admin" className="sideIcon" />
                <span className="menu-title">Admins</span>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
