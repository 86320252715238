import {
  DELETE_WEBSITE,
  GET_WEBSITES_PRODUCT_CATEGORIES,
  LIST_ALL_WEBSITES,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { setGlobalError } from "store/actions";
import {
  SET_WEBSITES,
  SET_WEBSITES_COUNT,
  SET_WEBSITES_PAGE,
  SET_WEBSITES_SIZE_PER_PAGE,
  SET_WEBSITES_SUCCESS,
  SET_WEBSITE_CATEGORIES,
  SET_WEBSITE_CATEGORIES_SUCCESS,
} from "./actionTypes";

export const fetchWebsites = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setWebsites(true));
      const res = await axiosMilliardAdmin.post(LIST_ALL_WEBSITES, payload);
      if (res?.data?.data) {
        dispatch(setWebsitesSuccess(res.data.data));
        dispatch(setWebsitesCount(res.data.data.length));
        cb(res.data.data);
      }
      dispatch(setWebsites(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setWebsites(false));
      }
    }
  };
};

export const deleteWebsite = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosMilliardAdmin.post(DELETE_WEBSITE, payload);
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
      }
    }
  };
};

export const fetchWebsiteCategories = (cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setWebsiteCategories(true));
      const res = await axiosMilliardAdmin.get(GET_WEBSITES_PRODUCT_CATEGORIES);
      if (res?.data?.data) {
        dispatch(setWebsiteCategoriesSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(setWebsiteCategories(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setWebsiteCategories(false));
      }
    }
  };
};

export const setWebsites = (data) => ({
  type: SET_WEBSITES,
  payload: data,
});

export const setWebsitesSuccess = (data) => ({
  type: SET_WEBSITES_SUCCESS,
  payload: data,
});

export const setWebsitesPage = (data) => ({
  type: SET_WEBSITES_PAGE,
  payload: data,
});

export const setWebsitesSizePerPage = (data) => ({
  type: SET_WEBSITES_SIZE_PER_PAGE,
  payload: data,
});

export const setWebsitesCount = (data) => ({
  type: SET_WEBSITES_COUNT,
  payload: data,
});

export const setWebsiteCategories = (data) => ({
  type: SET_WEBSITE_CATEGORIES,
  payload: data,
});

export const setWebsiteCategoriesSuccess = (data) => ({
  type: SET_WEBSITE_CATEGORIES_SUCCESS,
  payload: data,
});
