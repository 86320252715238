import React from "react";
import LandingCostTable from "../components/LandingCostTable";

const LandingCost = () => {
  return (
    <div>
      <LandingCostTable />
    </div>
  );
};

export default LandingCost;
