import { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/Table/CustomTable";
import { routesConstants } from "../../../routes/routesConstants";
import ConfirmationModel from "../../../components/common/ConfirmationModal";

import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  fetchProducts,
  setPage,
  setSizePerPage,
  setSortField,
  setSortOrder,
} from "../store/actions";
import useDebounced from "hooks/useDebounced";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants";
import Pagination from "components/common/Pagination";
import Select from "react-select";
import { fetchUsersList } from "store/actions";
import deleteIcon from "assets/icons/delete.png";
import viewIcon from "assets/icons/view.png";
import editIcon from "assets/icons/edit.png";

const Products = () => {
  const {
    productsList,
    loading,
    pageNo,
    sizePerPage,
    count,
    sortField,
    sortOrder,
  } = useSelector((state) => state.productReducer);

  const { permissions } = useSelector((state) => state.Auth);
  const { users } = useSelector((state) => state.adminReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filterId, setFilterId] = useState([]);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [productId, setProductId] = useState(null);
  const debounceSearch = useDebounced(search);

  const userList = users?.map((i) => ({
    ...i,
    label: i.first_name + " " + i.last_name,
    value: i.user_id,
  }));

  /* find data from userList permission wise */
  const matchingObjects =
    permissions?.is_all_users_selected === 0
      ? permissions?.selected_users?.map((userId) =>
          userList?.find((item) => item.user_id === userId)
        )
      : userList;

  /* delete product */
  const handleDeleteProduct = () => {
    dispatch(
      deleteProduct(
        {
          product_id: productId,
          is_from_admin: 1,
        },
        (res) => {
          if (res) {
            setIsDeleteModel(false);
            dispatch(setPage(1));
            dispatch(
              fetchProducts({
                pageNo: pageNo,
                pageRecords: sizePerPage,
                sortField: sortField,
                sortOrder: sortOrder,
                search: debounceSearch,
                is_from_admin: 1,
                userFilter: [],
              })
            );
          }
        }
      )
    );
  };

  /* fetch list of products */
  useEffect(() => {
    dispatch(
      fetchProducts({
        pageNo: pageNo,
        pageRecords: sizePerPage,
        sortField: sortField,
        sortOrder: sortOrder,
        search: debounceSearch,
        userFilter:
          filterId[0] === null ? [] : filterId?.map((i) => i?.user_id),
        is_from_admin: 1,
      })
    );
  }, [
    dispatch,
    pageNo,
    sizePerPage,
    sortField,
    sortOrder,
    debounceSearch,
    filterId,
  ]);

  useEffect(() => {
    dispatch(fetchUsersList());
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "Action",
      text: "Action",
      headerStyle: {
        width: "15%", // Set the width of the header cell
      },
      headerAlign: "center",
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns me-2"
              onClick={() => {
                navigate(`/${routesConstants.PRODUCTS}/view/${row.product_id}`);
              }}
              title="View Product"
            >
              <img src={viewIcon} alt="view" title="View" />
            </div>
            <div
              className="actionBtns me-2"
              onClick={() => {
                navigate(`/${routesConstants.PRODUCTS}/edit/${row.product_id}`);
              }}
              title="Edit Product"
            >
              <img src={editIcon} alt="edit" title="Edit" />
            </div>

            <div
              className="actionBtns"
              onClick={() => {
                setIsDeleteModel(true);
                setProductId(row.product_id);
              }}
              title="Delete Product"
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        );
      },
    },

    {
      dataField: "user_name",
      text: "User",
    },
    {
      dataField: "sku",
      text: "SKU",
      headerStyle: {
        width: "25%", // Set the width of the header cell
      },
    },
    {
      dataField: "total_variants",
      text: "Product Variants",
      headerStyle: {
        width: "15%", // Set the width of the header cell
      },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <button
              className="addbutton btn p-3 text-black shadow-sm fw-bold"
              onClick={() => {
                navigate(`/${routesConstants.PRODUCTS}/view/${row.product_id}`);
              }}
            >
              Manage Variants ({cell})
            </button>
          </div>
        );
      },
    },
    {
      dataField: "total_plans",
      text: "Total Plans",
      headerStyle: {
        width: "15%", // Set the width of the header cell
      },
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="d-flex">
            <button
              className="addbutton btn p-3 text-black shadow-sm fw-bold"
              onClick={() => navigate(`/selling-plans/${row?.product_id}`)}
            >
              Manage Plans ({cell})
            </button>
          </div>
        );
      },
    },
  ]);

  const pageOptions = useMemo(
    () => ({
      page: pageNo,
      sizePerPage: sizePerPage,
      totalSize: count,
      custom: true,
    }),
    [pageNo, sizePerPage, count]
  );

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    switch (type) {
      case "pagination":
        dispatch(setPage(page));
        dispatch(setSizePerPage(sizePerPage));
        break;
      case "sort":
        dispatch(setSortField(sortField));
        dispatch(setSortOrder(sortOrder));
        break;
      default:
        break;
    }
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setSizePerPage(value));
    dispatch(setPage(1));
  };

  const handlePageChange = (page) => {
    dispatch(setPage(page));
    dispatch(setSizePerPage(sizePerPage));
  };

  return (
    <>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom ">
          <h4>Products</h4>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <div className="d-flex productData">
            <Select
              placeholder="Filter by User"
              options={matchingObjects}
              onChange={(e) => {
                dispatch(setPage(1));
                setFilterId([e]);
              }}
              value={filterId}
              isClearable
              styles={{
                control: (base) => ({
                  ...base,
                  width: "200px",
                }),
                input: (base) => ({
                  ...base,
                  width: "200px",
                  padding: "8px",
                }),
              }}
            />
            <input
              type="search"
              className="searchInput"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        {loading ? (
          <div className="loader-spin">
            <Oval
              height={40}
              width={40}
              color="#464243"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#464243"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            <CustomTable
              keyField="product_id"
              data={productsList ? productsList : []}
              columns={columns}
              pageOptions={pageOptions}
              onTableChange={onTableChange}
              withCard
            />
            <div className="d-flex justify-content-between">
              <div></div>
              {count > 0 && (
                <div>
                  <Pagination
                    onChange={handlePageChange}
                    page={+pageNo}
                    sizePerPage={sizePerPage}
                    totalRecords={count}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Card>
      {isDeleteModel && (
        <ConfirmationModel
          cb={handleDeleteProduct}
          isOpen={isDeleteModel}
          handleClose={() => setIsDeleteModel(false)}
          title="Are you sure want to delete product?"
        />
      )}
    </>
  );
};

export default Products;
