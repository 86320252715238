import React from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { routesConstants } from "routes/routesConstants";
import { axiosMilliardAdmin } from "services/api";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";
import {
  fetchCountries,
  fetchWebsiteCategories,
  fetchWebsites,
} from "store/actions";
import * as Yup from "yup";
import { ADD_UPDATE_WEBSITE } from "constants/url";
import { toast } from "react-toastify";
import { useState } from "react";

const AddEditWebsite = () => {
  const { website_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  const { countries } = useSelector((state) => state.landingCostReducer);
  const { websites } = useSelector((state) => state.websitesReducer);

  const allCountries = countries?.map((i) => ({
    ...i,
    label: i.name,
    value: i.country_id,
  }));

  const singleWebsite = websites?.find((i) => +i.website_id === +website_id);

  const initialValues = {
    website_id: website_id ? website_id : 0,
    website_name: "",
    country_id: "",
    product_categories: [
      {
        website_product_id: 0,
        product_category: "Default",
        tier_1_commission: 0,
        tier_2_commission: 0,
        tier_condition: 0,
        is_default: 1,
        id: uuidv4(),
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    website_name: Yup.string().required("Website Name is required."),
    country_id: Yup.string().required("Country is required."),
    product_categories: Yup.array().of(
      Yup.object().shape({
        product_category: Yup.string().required("Category is required."),
        tier_1_commission: Yup.number()
          .nullable()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .min(1, "Less than 0")
          .required("Tier 1 is required."),
        tier_2_commission: Yup.number()
          .nullable()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .min(0, "Less than 0")
          .required("Tier 2 is required."),
        tier_condition: Yup.number()
          .nullable()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .min(0, "Less than 0")
          .required("Tier Condition is required."),
      })
    ),
  });

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(
      fetchWebsites(
        {
          search: "",
        },
        null
      )
    );
  }, [dispatch]);

  const onSubmit = async () => {
    const payload = {
      ...values,
      country_id: allCountries?.find((i) => i.label === values?.country_id)
        ?.country_id,
    };
    const res = await axiosMilliardAdmin.post(
      ADD_UPDATE_WEBSITE,
      website_id ? payload : values
    );

    if (res) {
      toast.success(res.data.message);
      navigate(`/${routesConstants.WEBSITES}`);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    errors,
    values,
    validateField,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (singleWebsite) {
      resetForm({
        values: {
          website_id: singleWebsite.website_id,
          website_name: singleWebsite.website_name,
          country_id: singleWebsite.country_name,
          product_categories: singleWebsite.website_products?.map((i) => ({
            ...i,
            website_product_id: i.website_product_id,
            product_category: i.product_category,
            tier_1_commission: i.tier_1_commission,
            tier_2_commission: i.tier_2_commission,
            tier_condition: i.tier_condition,
            is_default: i.is_default,
            id: uuidv4(),
          })),
        },
      });
    }
  }, [singleWebsite, resetForm]);

  useEffect(() => {
    dispatch(
      fetchWebsiteCategories((res) => {
        if (res) {
          setCategories(
            res.map((i) => ({
              label: i,
              value: i,
            }))
          );
        }
      })
    );
  }, [dispatch]);

  const handleAddRow = (indexToInsertAt) => {
    const newArray = {
      website_product_id: 0,
      product_category: "",
      tier_1_commission: 0,
      tier_2_commission: 0,
      tier_condition: 0,
      is_default: 0,
      id: uuidv4(),
    };
    // Create a copy of the values.data array
    const newDataArray = [...values.product_categories];
    // Insert the 'newArray' element at the specified index
    newDataArray.splice(indexToInsertAt + 1, 0, newArray);
    // Update the 'data' field in the form values
    setFieldValue("product_categories", newDataArray);
  };

  const handleDeleteRow = (item) => {
    let tempCustomOption = values.product_categories;

    tempCustomOption?.splice(
      values?.product_categories.findIndex((i) => i.id === item.id),
      1
    );
    setFieldValue({
      ...values,
      product_categories: tempCustomOption,
    });
  };

  const myErrors = errors.product_categories;
  return (
    <div className="col-12 col-xxl-7">
      <Card className="p-4 mt-5 add_admin_card website_data">
        <div className="top_title d-flex  justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>{website_id ? "Edit" : "Add"} Website </h4>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="popup_form_main">
            <div className="websiteDiv">
              <div className="row">
                <div className="col-6">
                  <div>
                    <label>Website:</label>
                  </div>
                  <input
                    type="text"
                    name="website"
                    value={values.website_name}
                    onChange={(e) => {
                      setFieldValue("website_name", e.target.value).then(() =>
                        validateField("website_name")
                      );
                    }}
                    placeholder="Website"
                    className="webInput"
                  />
                  {errors?.website_name && (
                    <div className="errorData">{errors?.website_name}</div>
                  )}
                </div>
                <div className="col-6">
                  <label>Country:</label>
                  <Select
                    placeholder="Select Country"
                    value={
                      singleWebsite
                        ? allCountries?.filter(
                            (i) => i.label === values?.country_id
                          )
                        : allCountries?.filter(
                            (i) => i.value === values?.country_id
                          )
                    }
                    onChange={(e) => {
                      if (singleWebsite) {
                        setFieldValue("country_id", e.label).then(() =>
                          validateField("country_id")
                        );
                      } else {
                        setFieldValue("country_id", e.value).then(() =>
                          validateField("country_id")
                        );
                      }
                    }}
                    options={allCountries}
                    styles={{
                      input: (base) => ({
                        ...base,
                        height: "33px !important",
                      }),
                    }}
                  />
                  {errors?.country_id && (
                    <div className="errorData">{errors?.country_id}</div>
                  )}
                </div>
              </div>
              <div className="row mt-3 pt-2">
                <div>
                  <label>Products:</label>
                </div>

                <div className="table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Tier 1</th>
                        <th>Tier 2</th>
                        <th>Tier 2 Applied Above</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.product_categories?.map((i, index) => (
                        <tr key={i.website_product_id} className="cat_table">
                          <td>
                            <div>
                              <CreatableSelect
                                isClearable
                                placeholder="Select"
                                name={`product_categories.[${index}].product_category`}
                                value={
                                  index === 0
                                    ? [
                                        {
                                          label: "Default",
                                          value: 0,
                                        },
                                      ]
                                    : categories?.filter(
                                        (x) => x.value === i["product_category"]
                                      )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_categories:
                                      values.product_categories?.map((i, x) => {
                                        if (+index === x)
                                          i.product_category = e?.value;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_categories.[${index}].product_category`
                                    )
                                  );
                                }}
                                onCreateOption={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_categories:
                                      values.product_categories?.map((i, x) => {
                                        if (+index === x)
                                          i.product_category = e;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_categories.[${index}].product_category`
                                    )
                                  );
                                  setCategories([
                                    ...categories,
                                    {
                                      label: e,
                                      value: e,
                                    },
                                  ]);
                                }}
                                styles={{
                                  input: (base) => ({
                                    ...base,
                                    height: "33px !important",
                                  }),
                                  control: (base) => ({
                                    ...base,
                                    minWidth: "200px",
                                    width: "200px",
                                  }),
                                }}
                                options={categories}
                                isDisabled={index === 0}
                              />
                              {myErrors
                                ? myErrors[index]?.product_category && (
                                    <div className="errorData">
                                      {
                                        errors.product_categories[index]
                                          .product_category
                                      }
                                    </div>
                                  )
                                : ""}
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <span className="input-group-text">%</span>
                              <div className="form-floating">
                                <input
                                  type="number"
                                  name={`product_categories.[${index}].tier_1_commission`}
                                  value={i["tier_1_commission"]}
                                  className="webInput"
                                  onChange={(e) => {
                                    setFieldValue({
                                      ...values,
                                      product_categories:
                                        values.product_categories.map(
                                          (i, x) => {
                                            if (index === x)
                                              i.tier_1_commission =
                                                e.target.value;
                                            return i;
                                          }
                                        ),
                                    }).then(() =>
                                      validateField(
                                        `product_categories.[${index}].tier_1_commission`
                                      )
                                    );
                                  }}
                                  // min={0}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {myErrors
                              ? myErrors[index]?.tier_1_commission && (
                                  <div className="errorData">
                                    {
                                      errors.product_categories[index]
                                        .tier_1_commission
                                    }
                                  </div>
                                )
                              : ""}
                          </td>

                          <td className="text-center">
                            <div>
                              <div className="input-group">
                                <span className="input-group-text">%</span>
                                <div className="form-floating">
                                  <input
                                    type="number"
                                    name={`product_categories.[${index}].tier_2_commission`}
                                    value={i["tier_2_commission"]}
                                    className="webInput"
                                    onChange={(e) => {
                                      setFieldValue({
                                        ...values,
                                        product_categories:
                                          values.product_categories.map(
                                            (i, x) => {
                                              if (index === x)
                                                i.tier_2_commission =
                                                  e.target.value;
                                              return i;
                                            }
                                          ),
                                      }).then(() =>
                                        validateField(
                                          `product_categories.[${index}].tier_2_commission`
                                        )
                                      );
                                    }}
                                    // min={0}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              {myErrors
                                ? myErrors[index]?.tier_2_commission && (
                                    <div className="errorData">
                                      {
                                        errors.product_categories[index]
                                          .tier_2_commission
                                      }
                                    </div>
                                  )
                                : ""}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="w_160 d-flex">
                                <input
                                  type="number"
                                  onWheel={(e) => e.preventDefault()}
                                  className="webInput"
                                  name={`product_categories.[${index}].tier_condition`}
                                  value={i["tier_condition"]}
                                  onChange={(e) => {
                                    setFieldValue({
                                      ...values,
                                      product_categories:
                                        values.product_categories.map(
                                          (i, x) => {
                                            if (index === x)
                                              i.tier_condition = e.target.value;
                                            return i;
                                          }
                                        ),
                                    }).then(() =>
                                      validateField(
                                        `product_categories.[${index}].tier_condition`
                                      )
                                    );
                                  }}
                                  // min={0}
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                                <div>
                                  <label className="ms-2">Selling Price</label>
                                </div>
                              </div>
                              {myErrors
                                ? myErrors[index]?.tier_condition && (
                                    <div className="errorData">
                                      {
                                        errors.product_categories[index]
                                          .tier_condition
                                      }
                                    </div>
                                  )
                                : ""}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex">
                              <button
                                title="Add"
                                type="button"
                                className="btn btn-primary btn_add_row p_11 me-2"
                                onClick={() => handleAddRow(index)}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              {values.product_categories?.length > 1 &&
                                index !== 0 && (
                                  <button
                                    title="Remove"
                                    type="button"
                                    className="btn btn-primary btn_add_row p_11"
                                    onClick={() => handleDeleteRow(i)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="form_btn d-flex mt-3 text-center justify-content-center flex-wrap gap-2">
                <button type="submit" className="btn w-100 p-2 btnSubmit ">
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/${routesConstants.WEBSITES}`)}
                  className="btn w-100 p-2 btnCancel"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddEditWebsite;
