import React from "react";
import { ADD_UPDATE_ADMIN } from "constants/url";
import { useFormik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { routesConstants } from "routes/routesConstants";
import { axiosMilliardAdmin } from "services/api";
import { fetchAdmins, fetchUsersList, setAdminPage } from "store/actions";
import * as Yup from "yup";
import LocalStorage from "services/localStorage";

const loginUserId = LocalStorage.get("login_user_id");

const AddEditAdmin = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { adminsList, users } = useSelector((state) => state.adminReducer);

  const { admin_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminData = adminsList?.find((i) => i.user_id === +admin_id);

  const userList = users?.map((i) => ({
    ...i,
    label: i.first_name + " " + i.last_name,
    value: i.user_id,
  }));

  const initialValues = {
    user_id: admin_id ? admin_id : 0,
    first_name: "",
    last_name: "",
    email: "",
    login_user_id: loginUserId,
    user_prmission_id: 1,
    is_users: 0,
    is_amazon_fees: 0,
    is_products: 0,
    is_landing_cost: 0,
    is_overhead_cost_group: 0,
    is_all_users_selected: 0,
    is_websites: 0,
    selected_users: [],
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required."),
    last_name: Yup.string().required("Last name is required."),
    email: Yup.string().email("Invalid email").required("Email is required."),
    is_users: Yup.string().test(
      "is-users",
      "At least one user should be select.",
      function (value, ctx) {
        if (
          ctx.parent.selected_users.length === 0 &&
          +ctx.parent.is_products === 1 &&
          +ctx.parent.is_all_users_selected === 0
        ) {
          return false;
        }
        return true;
      }
    ),
    is_overhead_cost_group: Yup.string().test(
      "permissions-module",
      "At least on should be select.",
      function (value, ctx) {
        if (
          +ctx.parent.is_users === 0 &&
          +ctx.parent.is_amazon_fees === 0 &&
          +ctx.parent.is_landing_cost === 0 &&
          +value === 0 &&
          +ctx.parent.is_products === 0 &&
          +ctx.parent.is_websites === 0
        ) {
          return false;
        }
        return true;
      }
    ),
  });

  const handleChangeData = (options) => {
    setFieldValue(
      "selected_users",
      options.map((i) => i.user_id)
    ).then(() => validateField("is_users"));
    setFieldValue("is_all_users_selected", 0);
    setSelectedOptions(options);
  };

  useEffect(() => {
    dispatch(
      fetchAdmins(
        {
          search: "",
        },
        null
      )
    );
    dispatch(fetchUsersList());
  }, [dispatch]);

  const onSubmit = async () => {
    try {
      const res = await axiosMilliardAdmin.post(ADD_UPDATE_ADMIN, values);
      if (res) {
        dispatch(setAdminPage(1));
        toast.success(res.data.message);
        navigate(`/${routesConstants.ADMINS}`);
      }
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    errors,
    values,
    validateField,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (adminData) {
      resetForm({
        values: {
          user_id: adminData?.user_id,
          first_name: adminData?.first_name,
          last_name: adminData?.last_name,
          email: adminData?.email,
          login_user_id: loginUserId,
          user_prmission_id: adminData?.user_permission.user_prmission_id,
          is_users: adminData?.user_permission.is_users,
          is_amazon_fees: adminData?.user_permission.is_amazon_fees,
          is_products: adminData?.user_permission.is_products,
          is_landing_cost: adminData?.user_permission.is_landing_cost,
          is_overhead_cost_group:
            adminData?.user_permission.is_overhead_cost_group,
          is_websites: adminData?.user_permission.is_websites,
          is_all_users_selected:
            adminData?.user_permission.is_all_users_selected,
          selected_users: adminData?.user_permission?.selected_users,
        },
      });
      if (userList && +values?.is_all_users_selected === 0) {
        const matchingObjects = values.selected_users?.map((userId) =>
          userList?.find((item) => item.user_id === userId)
        );
        setSelectedOptions(matchingObjects);
      } else {
        setSelectedOptions({
          label: "All Users",
          value: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, adminData, resetForm, users]);

  return (
    <div className="col-12 col-xxl-7">
      <Card className="p-4 mt-5 add_admin_card">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>{admin_id ? "Edit Admin" : "Add Admin"} </h4>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="formData">
              <div className="formInput">
                <label>First Name:</label>
                <input
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  onChange={(e) => {
                    setFieldValue("first_name", e.target.value).then(() =>
                      validateField("first_name")
                    );
                  }}
                />
                {errors.first_name && (
                  <div className="errorText errMargin">{errors.first_name}</div>
                )}
              </div>
              <div className="formInput">
                <label>Last Name:</label>
                <input
                  type="text"
                  name="last_name"
                  value={values.last_name}
                  onChange={(e) => {
                    setFieldValue("last_name", e.target.value).then(() =>
                      validateField("last_name")
                    );
                  }}
                />
                {errors.last_name && (
                  <div className="errorText errMargin">{errors.last_name}</div>
                )}
              </div>
              <div className="formInput">
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value).then(() =>
                      validateField("email")
                    );
                  }}
                  disabled={admin_id}
                />
                {errors.email && (
                  <div className="errorText errMargin">{errors.email}</div>
                )}
              </div>
              <div className="permission">
                <label>Permissions:</label>
                <div className="mt-3 containDiv">
                  <div className="d-flex justify-content-between form_all">
                    <div>
                      <div className="d-flex align-content-center">
                        <input
                          type="checkbox"
                          name="is_users"
                          id="users"
                          value={+values.is_users}
                          checked={+values.is_users}
                          onChange={(e) => {
                            setFieldValue(
                              "is_users",
                              e.target.checked ? 1 : 0
                            ).then(() =>
                              validateField("is_overhead_cost_group")
                            );
                          }}
                        />
                        <label for="users">Manage Users</label>
                      </div>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          name="is_landing_cost"
                          id="cost"
                          value={+values.is_landing_cost}
                          checked={+values.is_landing_cost}
                          onChange={(e) => {
                            setFieldValue(
                              "is_landing_cost",
                              e.target.checked ? 1 : 0
                            ).then(() =>
                              validateField("is_overhead_cost_group")
                            );
                          }}
                        />
                        <label for="cost">Manage Landed Cost</label>
                      </div>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          name="is_overhead_cost_group"
                          id="Overhead"
                          checked={+values?.is_overhead_cost_group}
                          value={+values?.is_overhead_cost_group}
                          onChange={(e) => {
                            setFieldValue(
                              "is_overhead_cost_group",
                              e.target.checked ? 1 : 0
                            ).then(() =>
                              validateField("is_overhead_cost_group")
                            );
                          }}
                        />
                        <label for="Overhead">Manage Overhead Cost Group</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="is_amazon_fees"
                          id="fees"
                          value={+values.is_amazon_fees}
                          checked={+values.is_amazon_fees}
                          onChange={(e) => {
                            setFieldValue(
                              "is_amazon_fees",
                              e.target.checked ? 1 : 0
                            ).then(() =>
                              validateField("is_overhead_cost_group")
                            );
                          }}
                        />
                        <label for="fees">Manage Amazon Fees</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="is_websites"
                          id="website"
                          value={+values.is_websites}
                          checked={+values.is_websites}
                          onChange={(e) => {
                            setFieldValue(
                              "is_websites",
                              e.target.checked ? 1 : 0
                            ).then(() =>
                              validateField("is_overhead_cost_group")
                            );
                          }}
                        />
                        <label for="website">Manage Websites</label>
                      </div>
                    </div>
                    <div className="userDiv">
                      <div className="userselect_inner">
                        <div>
                          <input
                            type="checkbox"
                            name="is_products"
                            id="Products"
                            value={+values.is_products}
                            checked={+values.is_products}
                            onChange={(e) => {
                              setFieldValue(
                                "is_products",
                                e.target.checked ? 1 : 0
                              ).then(() =>
                                validateField("is_overhead_cost_group")
                              );
                              setFieldValue("selected_users", []);
                              setSelectedOptions([]);
                              setFieldValue("is_all_users_selected", 0);
                            }}
                          />
                          <label for="Products">Manage Products</label>
                        </div>
                        {values?.is_products === 1 && (
                          <>
                            <div>
                              <span
                                onClick={() => {
                                  setFieldValue("is_all_users_selected", 1);
                                  setFieldValue("selected_users", []).then(() =>
                                    validateField("is_users")
                                  );
                                  setSelectedOptions({
                                    label: "All Users",
                                    value: 0,
                                  });
                                }}
                                className="ms-3"
                              >
                                All
                              </span>{" "}
                              |{" "}
                              <span
                                onClick={() => {
                                  setFieldValue("selected_users", []);
                                  setSelectedOptions([]);
                                  setFieldValue("is_all_users_selected", 0);
                                }}
                              >
                                Clear
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                      {+values?.is_products === 1 && (
                        <div className="userSelect">
                          <Select
                            isMulti
                            noOptionsMessage="No user found"
                            placeholder="Search"
                            value={selectedOptions}
                            onChange={handleChangeData}
                            options={userList}
                            isDisabled={+values.is_all_users_selected === 1}
                            styles={{
                              indicatorsContainer: (base) => ({
                                ...base,
                                display: "none",
                              }),
                            }}
                          />
                          {errors?.is_users && (
                            <div className="errorText">{errors.is_users}</div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {errors?.is_overhead_cost_group && (
                <div className="errorText">{errors.is_overhead_cost_group}</div>
              )}
              <div className="form_btn d-flex mt-3 text-center justify-content-center flex-wrap gap-2">
                <button type="submit" className="btn w-100 p-2 btnSubmit ">
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/${routesConstants.ADMINS}`)}
                  className="btn w-100 p-2 btnCancel"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddEditAdmin;
