export const routesConstants = {
  AMAZON_FEES: "amazon-fees",
  PRODUCTS: "products",
  AUTH: "auth",
  LOGIN: "login",
  SET_PASSWORD: "set-password/:token",
  FORGOT_PASSWORD: "forgot-password",
  USERS: "users",
  ADMINS: "admins",
  ADD_ADMIN: "admins/create",
  EDIT_ADMIN: "admins/edit/:admin_id",
  VIEW_ADMIN: "admins/view/:admin_id",
  EDIT_PRODUCT: "products/edit/:product_id",
  VIEW_PRODUCT: "products/view/:product_id",
  add_edit_product: "/add-edit-product",
  LANDING_COST: "landed-cost",
  ADD_LANDING_COST: "landed-cost/create",
  EDIT_LANDING_COST: "landed-cost/edit/:landing_costs_id",
  VIEW_LANDING_COST: "landed-cost/view/:landing_costs_id",
  OVERHEAD_COST_GROUP: "overhead-cost-group",
  ADD_OVERHEAD_COST_GROUP: "overhead-cost-group/create",
  EDIT_OVERHEAD_COST_GROUP: "overhead-cost-group/edit/:overhead_id",
  VIEW_OVERHEAD_COST_GROUP: "overhead-cost-group/view/:overhead_id",
  WEBSITES: "websites",
  ADD_WEBSITE: "websites/create",
  EDIT_WEBSITE: "websites/edit/:website_id",
  VIEW_WEBSITE: "websites/view/:website_id",
  SELLING_PLANS: "selling-plans/:product_id",
};
