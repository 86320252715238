import {
  SET_WEBSITES,
  SET_WEBSITES_SUCCESS,
  SET_WEBSITES_ERROR,
  SET_WEBSITES_PAGE,
  SET_WEBSITES_SIZE_PER_PAGE,
  SET_WEBSITES_COUNT,
  SET_WEBSITE_CATEGORIES,
  SET_WEBSITE_CATEGORIES_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  error: "",
  websites: null,
  websiteCategories: null,
  page: 1,
  sizePerPage: 10,
  count: 0,
};

const websitesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_WEBSITES:
      return {
        ...state,
        loading: payload,
      };
    case SET_WEBSITES_SUCCESS:
      return {
        ...state,
        websites: payload,
      };
    case SET_WEBSITES_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_WEBSITES_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_WEBSITES_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_WEBSITES_COUNT:
      return {
        ...state,
        count: payload,
      };
    case SET_WEBSITE_CATEGORIES:
      return {
        ...state,
        loading: payload,
      };
    case SET_WEBSITE_CATEGORIES_SUCCESS:
      return {
        ...state,
        websiteCategories: payload,
      };
    default:
      return state;
  }
};

export default websitesReducer;
