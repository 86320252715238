import {
  DELETE_OVERHEAD_COST_GROUP,
  GET_ALL_MASTER_COST_GROUP_TYPES,
  GET_OVERHEAD_COST_GROUP_DETAIL,
  LIST_ALL_OVERHEAD_COST_GROUPS,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { setGlobalError } from "store/actions";
import {
  SET_GROUP_DETAIL,
  SET_GROUP_DETAIL_SUCCESS,
  SET_MASTER_COST_GROUP_TYPES,
  SET_MASTER_COST_GROUP_TYPES_SUCCESS,
  SET_OVERHEAD_COST_GROUP,
  SET_OVERHEAD_COST_GROUP_SUCCESS,
  SET_OVERHEAD_PAGE,
  SET_OVERHEAD_SIZE_PER_PAGE,
} from "./actionTypes";

export const fetchMasterCostGroupTypes = () => {
  return async (dispatch) => {
    try {
      dispatch(setMasterCostGroupTypes(true));
      const res = await axiosMilliardAdmin.get(GET_ALL_MASTER_COST_GROUP_TYPES);
      if (res?.data?.data) {
        dispatch(setMasterCostGroupTypesSuccess(res.data.data));
      }
      dispatch(setMasterCostGroupTypes(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setMasterCostGroupTypes(false));
      }
    }
  };
};

export const fetchOverheadCostGroups = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setOverheadCostGroup(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_OVERHEAD_COST_GROUPS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setOverheadCostGroupSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(setOverheadCostGroup(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setOverheadCostGroup(false));
      }
    }
  };
};

export const fetchSingleOverheadGroupDetail = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setOverheadGroupDetail(true));
      const res = await axiosMilliardAdmin.post(
        GET_OVERHEAD_COST_GROUP_DETAIL,
        payload
      );
      if (res?.data?.data) {
        dispatch(setOverheadGroupDetailSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(setOverheadGroupDetail(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setOverheadGroupDetail(false));
      }
    }
  };
};

export const deleteOverheadCosts = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosMilliardAdmin.post(
        DELETE_OVERHEAD_COST_GROUP,
        payload
      );
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
      }
    }
  };
};

export const setMasterCostGroupTypes = (data) => ({
  type: SET_MASTER_COST_GROUP_TYPES,
  payload: data,
});

export const setMasterCostGroupTypesSuccess = (data) => ({
  type: SET_MASTER_COST_GROUP_TYPES_SUCCESS,
  payload: data,
});

export const setOverheadCostGroup = (data) => ({
  type: SET_OVERHEAD_COST_GROUP,
  payload: data,
});

export const setOverheadCostGroupSuccess = (data) => ({
  type: SET_OVERHEAD_COST_GROUP_SUCCESS,
  payload: data,
});

export const setOverheadGroupDetail = (data) => ({
  type: SET_GROUP_DETAIL,
  payload: data,
});

export const setOverheadGroupDetailSuccess = (data) => ({
  type: SET_GROUP_DETAIL_SUCCESS,
  payload: data,
});

export const setOverheadCostPage = (data) => ({
  type: SET_OVERHEAD_PAGE,
  payload: data,
});

export const setOverheadCostSizePerPage = (data) => ({
  type: SET_OVERHEAD_SIZE_PER_PAGE,
  payload: data,
});
