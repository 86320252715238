import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSellingPlans,
  fetchSellingTemplates,
  generateSellingPlan,
} from "../store/actions";
import { useParams } from "react-router-dom";
import { fetchProductDetails } from "store/actions";

const initialValues = {
  selling_plan_id: 0,
  selling_plan_name: "",
  selling_template_id: "",
  product_variant_id: "",
  selling_price: "",
  factory_cost: "",
};

const validationSchema = Yup.object().shape({
  selling_plan_name: Yup.string().required("Please enter selling plan name."),
  selling_template_id: Yup.string().required("Please select selling template."),
  product_variant_id: Yup.string().required("Please select product variant."),
  selling_price: Yup.string().required("Please enter selling price."),
  factory_cost: Yup.string().required("Please enter factory cost."),
});

const TemplateModal = ({
  title = "Create Selling Plan",
  isOpen,
  cb,
  handleClose,
  isEdit,
  planDetails,
  setPlanDetails,
}) => {
  const [extraData, setExtraData] = useState({
    sourceZip: "",
    destinationZip: "",
    landedCost: "",
    website: "",
    productType: "",
    overheadCostGroup: "",
    fulfillmentChannel: "",
  });

  const dispatch = useDispatch();
  const { product_id } = useParams();

  const { sellingTemplateList } = useSelector(
    (state) => state.sellingTemplateReducer
  );

  const { productDetail } = useSelector((state) => state.productReducer);

  const tempConst = sellingTemplateList?.map((i) => ({
    label: i.template_name,
    value: i.selling_template_id,
  }));

  const varConst = productDetail?.product_variants?.map((i) => ({
    label: i.product_variant_name,
    value: i.product_variant_id,
  }));

  useEffect(() => {
    dispatch(
      fetchSellingTemplates({
        search: "",
      })
    );
    dispatch(
      fetchProductDetails({
        product_id: product_id,
        is_from_admin: 1,
      })
    );
  }, [dispatch, product_id]);

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      selling_price: parseFloat(values?.selling_price),
      factory_cost: parseFloat(values?.factory_cost),
    };
    dispatch(
      generateSellingPlan(payload, (res) => {
        if (res) {
          dispatch(
            fetchSellingPlans({
              product_id: product_id,
              is_from_admin: 1,
            })
          );
          setPlanDetails("");
          resetForm({
            values: initialValues,
          });
        }
      })
    );
    cb();
  };

  const {
    setFieldValue,
    errors,
    handleSubmit,
    validateField,
    values,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (planDetails) {
      resetForm({
        values: {
          selling_plan_id: planDetails?.selling_plan_id,
          selling_plan_name: planDetails?.selling_plan_name,
          selling_template_id: planDetails?.selling_template_id,
          product_variant_id: planDetails?.product_variant_id,
          selling_price: planDetails?.summry?.selling_price,
          factory_cost: planDetails?.landed_cost?.factory_cost,
        },
      });
      setExtraData({
        sourceZip: planDetails.shipping_cost?.source_zip,
        destinationZip: planDetails.shipping_cost?.destination_zip,
        fulfillmentChannel:
          planDetails.shipping_cost?.shipping_preference === 1
            ? "FBA"
            : planDetails.shipping_cost?.shipping_preference === 2
            ? "FedEx"
            : "",
        landedCost:
          planDetails.landed_cost?.source +
          " to " +
          planDetails.landed_cost?.destination,
        website: planDetails.channel_cost?.website,
        productType: planDetails.channel_cost?.product_type,
        overheadCostGroup: planDetails.overhead_cost?.overhead_cost_group,
      });
    }
  }, [dispatch, resetForm, planDetails]);

  return (
    <Modal show={isOpen} className="fade delete_popup tempForm">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <Modal.Header className="lex-column d-inline-block">
            <h4 className="modal-title w-100">{title}</h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="tempData row">
                <div className="col-md-5 col-12">
                  <label>Selling Plan Name</label>
                </div>
                <div className="col-md-7 col-12">
                  <input
                    type="text"
                    className="form-control half_to_full"
                    placeholder="Selling Plan Name"
                    name="selling_plan_name"
                    onChange={(e) => {
                      setFieldValue("selling_plan_name", e.target.value).then(
                        () => validateField("selling_plan_name")
                      );
                    }}
                    value={values?.selling_plan_name}
                  />
                  {errors?.selling_plan_name && (
                    <div className="errorData">{errors?.selling_plan_name}</div>
                  )}
                </div>
              </div>
              {!isEdit && (
                <div className="tempData row">
                  <div className="col-md-5 col-12">
                    <label>Selling Templates</label>
                  </div>
                  <div className="col-md-7 col-12">
                    <Select
                      options={tempConst}
                      name="selling_template_id"
                      styles={{
                        control: (base) => ({
                          ...base,
                          padding: 0,
                          width: "100%",
                          textAlign: "left",
                        }),
                        menu: (base) => ({
                          ...base,
                          padding: 0,
                          width: "100%",
                          textAlign: "left",
                        }),
                      }}
                      onChange={(e) => {
                        setFieldValue("selling_template_id", e.value).then(() =>
                          validateField("selling_template_id")
                        );
                      }}
                      value={tempConst?.filter(
                        (i) => i.value === values?.selling_template_id
                      )}
                    />
                    {errors?.selling_template_id && (
                      <div className="errorData">
                        {errors?.selling_template_id}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={`tempData row`}>
                <div className="col-md-5 col-12">
                  <label>Product Variant</label>
                </div>
                <div className="col-md-7 col-12">
                  <Select
                    options={varConst}
                    name="product_variant_id"
                    styles={{
                      control: (base) => ({
                        ...base,
                        padding: 0,
                        width: "100%",
                        textAlign: "left",
                      }),
                      menu: (base) => ({
                        ...base,
                        padding: 0,
                        width: "100%",
                        textAlign: "left",
                      }),
                    }}
                    onChange={(e) => {
                      setFieldValue("product_variant_id", e.value).then(() =>
                        validateField("product_variant_id")
                      );
                    }}
                    value={varConst?.filter(
                      (i) => i.value === values?.product_variant_id
                    )}
                  />
                  {errors?.product_variant_id && (
                    <div className="errorData">
                      {errors?.product_variant_id}
                    </div>
                  )}
                </div>
              </div>

              {isEdit && (
                <>
                  <div className="tempData row">
                    <div className="col-md-5 col-12">
                      <label>Landed Cost</label>
                    </div>
                    <div className="col-md-7 col-12">
                      <input
                        type="text"
                        className="form-control half_to_full"
                        value={extraData?.landedCost}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="tempData row">
                    <div className="col-md-5 col-12">
                      <label>Website</label>
                    </div>
                    <div className="col-md-7 col-12">
                      <input
                        type="text"
                        className="form-control half_to_full"
                        value={extraData?.website}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="tempData row">
                    <div className="col-md-5 col-12">
                      <label>Product Type</label>
                    </div>
                    <div className="col-md-7 col-12">
                      <input
                        type="text"
                        className="form-control half_to_full"
                        value={extraData?.productType}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="tempData row">
                    <div className="col-md-5 col-12">
                      <label>Overhead Cost Group</label>
                    </div>
                    <div className="col-md-7 col-12">
                      <input
                        type="text"
                        className="form-control half_to_full"
                        value={extraData?.overheadCostGroup}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="tempData row">
                    <div className="col-md-5 col-12">
                      <label>Fulfillment Channel</label>
                    </div>
                    <div className="col-md-7 col-12">
                      <input
                        type="text"
                        className="form-control half_to_full"
                        value={extraData?.fulfillmentChannel}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="tempData row">
                <div className="col-md-5 col-12">
                  <label>Selling Price (SP)</label>
                </div>
                <div className="col-md-7 col-12">
                  <input
                    type="number"
                    className="form-control half_to_full"
                    placeholder="Selling Price"
                    name="selling_price"
                    onChange={(e) => {
                      setFieldValue("selling_price", e.target.value).then(() =>
                        validateField("selling_price")
                      );
                    }}
                    value={values?.selling_price}
                  />
                  {errors?.selling_price && (
                    <div className="errorData">{errors?.selling_price}</div>
                  )}
                </div>
              </div>
              <div className="tempData row">
                <div className="col-md-5 col-12">
                  <label>Factory Cost (FC)</label>
                </div>
                <div className="col-md-7 col-12">
                  <input
                    type="number"
                    className="form-control half_to_full"
                    placeholder="Factory Cost"
                    name="factory_cost"
                    onChange={(e) => {
                      setFieldValue("factory_cost", e.target.value).then(() =>
                        validateField("factory_cost")
                      );
                    }}
                    value={values?.factory_cost}
                  />
                  {errors?.factory_cost && (
                    <div className="errorData">{errors?.factory_cost}</div>
                  )}
                </div>
              </div>
              {isEdit &&
                +planDetails.shipping_cost?.shipping_preference === 2 && (
                  <>
                    <div className="tempData row">
                      <div className="col-md-5 col-12">
                        <label>Source Zip</label>
                      </div>
                      <div className="col-md-7 col-12">
                        <input
                          type="number"
                          className="form-control half_to_full"
                          placeholder="Source Zip"
                          onChange={(e) => {
                            setExtraData({
                              ...extraData,
                              sourceZip: e.target.value,
                            });
                          }}
                          value={extraData?.sourceZip}
                        />
                      </div>
                    </div>
                    <div className="tempData row">
                      <div className="col-md-5 col-12">
                        <label>Destination Zip</label>
                      </div>
                      <div className="col-md-7 col-12">
                        <input
                          type="number"
                          className="form-control half_to_full"
                          placeholder="Destination Zip"
                          onChange={(e) => {
                            setExtraData({
                              ...extraData,
                              destinationZip: e.target.value,
                            });
                          }}
                          value={extraData?.destinationZip}
                        />
                      </div>
                    </div>
                  </>
                )}
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="addbutton btn p-3 text-black shadow-sm fw-bold"
                  type="submit"
                >
                  Generate
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateModal;
