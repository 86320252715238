import PropTypes from "prop-types";

import styles from "./index.module.css";

import TablePagination from "components/common/Pagination/TablePagination";

const Pagination = ({ totalRecords, page, sizePerPage, onChange }) => {
  const totalPages = Math.ceil(totalRecords / sizePerPage);
  return (
    <div className="row">
      {/* <div className="col-4"></div>
      <div className="col-4"></div> */}
      <div
        className={`${styles["pagination-container"]} ${styles["pagination-photo"]} col-4`}
      >
        <TablePagination
          handlePageChange={onChange}
          page={page}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
