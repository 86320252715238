export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_COUNTRIES_SUCCESS = "SET_COUNTRIES_SUCCESS";
export const SET_COUNTRIES_ERROR = "SET_COUNTRIES_ERROR";

export const SET_LANDING_COSTS = "SET_LANDING_COSTS";
export const SET_LANDING_COSTS_SUCCESS = "SET_LANDING_COSTS_SUCCESS";
export const SET_LANDING_COSTS_ERROR = "SET_LANDING_COSTS_ERROR";

export const SET_LANDING_COSTS_PAGE = "SET_LANDING_COSTS_PAGE";
export const SET_LANDING_COSTS_SIZE_PER_PAGE =
  "SET_LANDING_COSTS_SIZE_PER_PAGE";
export const SET_LANDING_COSTS_COUNT = "SET_LANDING_COSTS_COUNT";
