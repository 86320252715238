import React from "react";
import WebsitesTable from "../components/WebsitesTable";

const Websites = () => {
  return (
    <div>
      <WebsitesTable />
    </div>
  );
};

export default Websites;
