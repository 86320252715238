import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import ConfirmationModal from "components/common/ConfirmationModal";
import { setVerifyLinkSuccess } from "store/actions";
import { routesConstants } from "routes/routesConstants";
import { useEffect } from "react";
import { axiosMilliardAdmin } from "services/api";
import { VERIFY_SET_PASSWORD_LINK } from "constants/url";
import { useState } from "react";

const CheckPasswordPagePermission = () => {
  const [isError, setIsError] = useState();
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleVerifyPassword = async () => {
    try {
      const res = await axiosMilliardAdmin.post(VERIFY_SET_PASSWORD_LINK, {
        token: token,
      });
      if (res) {
        dispatch(setVerifyLinkSuccess(res.data.data));
      }
    } catch (err) {
      setIsError(err);
    }
  };
  useEffect(() => {
    handleVerifyPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return isError ? (
    <ConfirmationModal
      type="warning"
      btnText="Ok"
      title="Invalid Page URL"
      subTitle=""
      isOpen={true}
      handleClose={() => {
        navigate(`/${routesConstants.LOGIN}`);
        setIsError("");
      }}
      cb={() => {
        navigate(`/${routesConstants.LOGIN}`);
        setIsError("");
      }}
      modalProps={{
        backdrop: "static",
        modalClassName: "pe-none",
      }}
    />
  ) : (
    <Outlet />
  );
};

export default CheckPasswordPagePermission;
