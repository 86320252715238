import {
  SET_PAGE,
  SET_PRODUCTS,
  SET_PRODUCTS_ERRORS,
  SET_PRODUCTS_SUCCESS,
  SET_SIZE_PER_PAGE,
  SET_TOTAL_COUNT,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_PRODUCT_DETAILS,
  SET_PRODUCT_DETAILS_SUCCESS,
} from "./actionTypes";

const initialState = {
  productsList: null,
  productDetail: null,
  loading: false,
  error: "",
  pageNo: 1,
  sizePerPage: 10,
  count: 0,
  sortField: "product_id",
  sortOrder: "asc",
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCTS:
      return {
        ...state,
        loading: payload,
      };
    case SET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: payload,
      };
    case SET_PRODUCTS_ERRORS:
      return {
        ...state,
        error: payload,
      };
    case SET_PRODUCT_DETAILS:
      return {
        ...state,
        loading: payload,
      };
    case SET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetail: payload,
      };
    case SET_PAGE:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        count: payload,
      };
    case SET_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    default:
      return state;
  }
};

export default productReducer;
