import { GET_USER_PERMISSION } from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import {
  SET_AUTH,
  SET_PAGE_ERROR,
  SET_PERMISSIONS,
  SET_PERMISSIONS_ERROR,
  SET_PERMISSIONS_SUCCESS,
  SET_USER,
  SET_VERIFY_LINK,
  SET_VERIFY_LINK_SUCCESS,
} from "./actionTypes";

export const fetchUserPermissions = () => {
  return async (dispatch) => {
    try {
      dispatch(setPermissions(true));
      const res = await axiosMilliardAdmin.get(GET_USER_PERMISSION);
      if (res?.data?.data) {
        dispatch(setPermissionsSuccess(res.data.data));
      }
      dispatch(setPermissions(false));
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
      dispatch(setPermissions(false));
    }
  };
};

export const getGlobalError = (e) => {
  return null;
};

export const setGlobalError = (payload) => ({
  type: SET_PAGE_ERROR,
  payload,
});

export const handleSetAuth = (data) => ({
  type: SET_AUTH,
  payload: data,
});

export const setUserData = (data) => ({
  type: SET_USER,
  payload: data,
});

export const setPermissions = (data) => ({
  type: SET_PERMISSIONS,
  payload: data,
});

export const setPermissionsSuccess = (data) => ({
  type: SET_PERMISSIONS_SUCCESS,
  payload: data,
});

export const setPermissionsError = (data) => ({
  type: SET_PERMISSIONS_ERROR,
  payload: data,
});

export const setVerifyLink = (data) => ({
  type: SET_VERIFY_LINK,
  payload: data,
});

export const setVerifyLinkSuccess = (data) => ({
  type: SET_VERIFY_LINK_SUCCESS,
  payload: data,
});
