import { useEffect } from "react";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalStorage from "./services/localStorage";
import { fetchUserPermissions, handleSetAuth } from "./store/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    let token = LocalStorage.get("token");
    if (!!token) {
      dispatch(handleSetAuth(true));
      dispatch(fetchUserPermissions());
    }
  }, [dispatch, location?.pathname]);

  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
};
export default App;
