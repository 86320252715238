import {
  LIST_SELLING_PLANS,
  LIST_SELLING_PLANS_SUCCESS,
  SET_LANDING_COSTS,
  SET_LANDING_COSTS_SUCCESS,
  SET_OVERHEAD_COST_GROUP,
  SET_OVERHEAD_COST_GROUP_SUCCESS,
  SET_SELLING_PLANS,
  SET_SELLING_PLANS_SUCCESS,
  SET_SELLING_TEMPLATES,
  SET_SELLING_TEMPLATES_SUCCESS,
  SET_SOURCE_DEST,
  SET_SOURCE_DEST_SUCCESS,
  SET_TEMPLATE_DETAIL,
  SET_TEMPLATE_DETAIL_SUCCESS,
  SET_TEMPLATE_PAGE,
  SET_TEMPLATE_SIZE_PER_PAGE,
  SET_TEMPLATE_TOTAL_COUNT,
  SET_WEBSITES,
  SET_WEBSITES_SUCCESS,
} from "./actionTypes";

const initialState = {
  sellingTemplateList: null,
  landingCosts: null,
  templateDetail: null,
  sellingPlan: null,
  sellingPlansList: null,
  overheadCostGroups: null,
  websites: null,
  countries: null,
  loading: false,
  error: "",
  pageNo: 1,
  sizePerPage: 10,
  count: 0,
};

const sellingTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELLING_TEMPLATES:
      return {
        ...state,
        loading: payload,
      };
    case SET_SELLING_TEMPLATES_SUCCESS:
      return {
        ...state,
        sellingTemplateList: payload,
      };
    case SET_OVERHEAD_COST_GROUP:
      return {
        ...state,
        loading: payload,
      };
    case SET_OVERHEAD_COST_GROUP_SUCCESS:
      return {
        ...state,
        overheadCostGroups: payload,
      };
    case SET_LANDING_COSTS:
      return {
        ...state,
        loading: payload,
      };
    case SET_LANDING_COSTS_SUCCESS:
      return {
        ...state,
        landingCosts: payload,
      };
    case SET_SELLING_PLANS:
      return {
        ...state,
        loading: payload,
      };
    case SET_SELLING_PLANS_SUCCESS:
      return {
        ...state,
        sellingPlan: payload,
      };
    case SET_WEBSITES:
      return {
        ...state,
        loading: payload,
      };
    case SET_WEBSITES_SUCCESS:
      return {
        ...state,
        websites: payload,
      };
    case SET_SOURCE_DEST:
      return {
        ...state,
        loading: payload,
      };
    case SET_SOURCE_DEST_SUCCESS:
      return {
        ...state,
        countries: payload,
      };
    case SET_TEMPLATE_PAGE:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_TEMPLATE_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_TEMPLATE_TOTAL_COUNT:
      return {
        ...state,
        count: payload,
      };
    case SET_TEMPLATE_DETAIL:
      return {
        ...state,
        loading: payload,
      };
    case SET_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        templateDetail: payload,
      };
    case LIST_SELLING_PLANS:
      return {
        ...state,
        loading: payload,
      };
    case LIST_SELLING_PLANS_SUCCESS:
      return {
        ...state,
        sellingPlansList: payload,
      };
    default:
      return state;
  }
};

export default sellingTemplateReducer;
