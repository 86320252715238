import { groupUnits } from "constants";
import { ADD_EDIT_OVERHEAD_COST_GROUP } from "constants/url";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { routesConstants } from "routes/routesConstants";
import { axiosMilliardAdmin } from "services/api";
import * as Yup from "yup";
import {
  fetchMasterCostGroupTypes,
  fetchSingleOverheadGroupDetail,
  setOverheadCostPage,
} from "../store/actions";

const AddEditOverheadGroup = () => {
  const { overhead_id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { masterCostTypes, singleCostGroup } = useSelector(
    (state) => state.overheadCostReducer
  );

  const initialValues = {
    overhead_group_id: 0,
    group_title: "",
    overhead_groups_details: [],
  };

  const validationSchema = Yup.object().shape({
    group_title: Yup.string().required("Group title is required."),
    overhead_groups_details: Yup.array().of(
      Yup.object().shape({
        cost_types_masters: Yup.array().of(
          Yup.object().shape({
            cost: Yup.number()
              .nullable()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .required("Required"),
            cost_unit: Yup.number().test(
              "cost-unit-validation",
              "Cost Unit is Required.",
              function (value) {
                const { cost } = this.parent;
                if (+cost > 0 && +value === 0) {
                  return false;
                }
                return true;
              }
            ),
          })
        ),
      })
    ),
  });

  const onSubmit = async () => {
    var indices = [];
    const test = values.overhead_groups_details?.map((i) =>
      i.cost_types_masters?.map((x) => x.cost === 0 || x.cost === "")
    );

    // eslint-disable-next-line array-callback-return
    test.map(function (yourArray) {
      // eslint-disable-next-line array-callback-return
      yourArray.filter(function (a) {
        if (a === true) {
          indices.push(true);
        } else {
          indices.push(false);
        }
      });
    });

    /* this function is used for at least one overhead cost is required */
    const allEqual = (arr) => arr.every((v) => v === true);

    const overheadDetails = values.overhead_groups_details?.reduce(
      (acc, item) => {
        const costs = item.cost_types_masters.map(
          ({ overhead_group_detail_id, cost_type_id, cost_unit, cost }) => ({
            overhead_group_detail_id,
            cost_type_id,
            cost_unit,
            cost,
          })
        );
        return acc.concat(costs);
      },
      []
    );

    const payload = {
      overhead_group_id: overhead_id ? overhead_id : 0,
      group_title: values.group_title,
      overhead_groups_details: overheadDetails,
    };

    if (!allEqual(indices)) {
      const res = await axiosMilliardAdmin.post(
        ADD_EDIT_OVERHEAD_COST_GROUP,
        payload
      );
      if (res) {
        toast.success(res.data.message);
        navigate(`/${routesConstants.OVERHEAD_COST_GROUP}`);
        dispatch(setOverheadCostPage(1));
      }
    } else {
      toast.error("Please enter at least one value.");
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    values,
    errors,
    validateField,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  const myErrors = errors?.overhead_groups_details;

  useEffect(() => {
    /* fetch overhead cost groups */
    dispatch(fetchMasterCostGroupTypes());

    /* fetch single overhead group details by overhead_id */
    if (overhead_id) {
      dispatch(
        fetchSingleOverheadGroupDetail({
          overhead_group_id: +overhead_id,
        })
      );
    }
  }, [dispatch, overhead_id]);

  useEffect(() => {
    if (masterCostTypes && !overhead_id) {
      resetForm({
        values: {
          overhead_group_id: 0,
          group_title: "",
          overhead_groups_details: masterCostTypes.map((item) => ({
            ...item,
            cost_types_masters: item.cost_types_masters.map((costType) => ({
              ...costType,
              overhead_group_detail_id: 0,
              cost_type_id: costType.cost_type_id,
              cost_unit: 0,
              cost: 0,
            })),
          })),
        },
      });
    }
  }, [masterCostTypes, overhead_id, resetForm]);

  useEffect(() => {
    if (singleCostGroup) {
      resetForm({
        values: {
          overhead_group_id: +overhead_id,
          group_title: singleCostGroup.group_title,
          overhead_groups_details:
            singleCostGroup.overhead_cost_groups_details.map((item) => ({
              ...item,
              cost_types_masters: item.cost_types_masters.map((costType) => ({
                ...costType,
                overhead_group_detail_id: costType.overhead_group_detail_id,
                cost_type_id: costType.cost_type_id,
                cost_unit: costType.cost_unit,
                cost: costType.cost,
              })),
            })),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleCostGroup, resetForm, dispatch, overhead_id]);

  /* set cost value */
  const handleChangeValue = (i, value) => {
    const updatedValue = values.overhead_groups_details.map((item) => {
      if (item.cost_group_id === i.cost_group_id) {
        return {
          ...item,
          cost_types_masters: item.cost_types_masters.map((costType) => {
            if (costType.cost_type_id === i.cost_type_id) {
              return { ...costType, cost: value };
            }
            return costType;
          }),
        };
      }
      return item;
    });
    setFieldValue("overhead_groups_details", updatedValue);
  };

  /* set unit value */
  const handleUnitValue = (i, value) => {
    const updatedValue = values.overhead_groups_details?.map((item) => {
      if (item.cost_group_id === i.cost_group_id) {
        return {
          ...item,
          cost_types_masters: item.cost_types_masters?.map((costType) => {
            if (costType.cost_type_id === i.cost_type_id) {
              return { ...costType, cost_unit: value };
            }
            return costType;
          }),
        };
      }
      return item;
    });
    setFieldValue("overhead_groups_details", updatedValue);
  };

  return (
    <div className="col-12 col-xxl-5">
      <Card className="p-4 mt-5 add_overhead_cost_card">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>
            {overhead_id
              ? "Edit Overhead Cost Group"
              : "Add Overhead Cost Group"}
          </h4>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <div className="singleRow">
                  <label>Group Title:</label>
                  <input
                    type="text"
                    name="group_title"
                    placeholder="Group Title"
                    value={values.group_title}
                    onChange={(e) => {
                      setFieldValue("group_title", e.target.value).then(() =>
                        validateField("group_title")
                      );
                    }}
                  />
                </div>
                {errors?.group_title && (
                  <div className="errorText margin_left_30 mb-2">
                    {errors.group_title}
                  </div>
                )}
                {values?.overhead_groups_details?.map((i, xindex) => (
                  <div className="mt-3" key={xindex}>
                    <div className="row">
                      <span className="fontBig col-6">
                        {i.cost_group_title}
                      </span>
                      <span className="fontBig col-3">Amount</span>
                      <span className="fontBig col-3">Per</span>
                    </div>
                    <div className="containDiv">
                      {i.cost_types_masters?.map((x, index) => (
                        <>
                          <div className="singleRow" key={index}>
                            <label className="pb-3">{x.cost_type}:</label>

                            <div className="doller_input_main">
                              <span className="input-group-text">$</span>
                              <input
                                type="number"
                                placeholder=" Amount"
                                name={
                                  values.overhead_groups_details[xindex]
                                    .cost_types_masters[index]?.cost
                                }
                                value={
                                  values.overhead_groups_details[xindex]
                                    .cost_types_masters[index]?.cost
                                }
                                onChange={(e) =>
                                  handleChangeValue(x, e.target.value)
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                            </div>

                            <Select
                              options={groupUnits}
                              placeholder="Select Unit"
                              name={
                                values.overhead_groups_details[xindex]
                                  .cost_types_masters[index].cost_unit
                              }
                              value={groupUnits?.filter(
                                (i) =>
                                  i.value ===
                                  +values.overhead_groups_details[xindex]
                                    .cost_types_masters[index].cost_unit
                              )}
                              onChange={(e) => {
                                handleUnitValue(x, e.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  border: "1px solid #e8e8e8 !important",
                                  width: "20% !important",
                                }),
                                control: (base) => ({
                                  ...base,
                                  border: "none !important",
                                  outline: "none !important",
                                  boxShadow: "none !important",
                                  minHeight: "35px !important",
                                }),
                                input: (base) => ({
                                  ...base,
                                  height: "30px !important",
                                  border: "none !important",
                                  marginTop: "-18px",
                                  opacity: 0,
                                }),
                              }}
                            />
                          </div>
                          {myErrors ? (
                            myErrors[xindex]?.cost_types_masters[index]
                              ?.cost ? (
                              <div className="eData">
                                {
                                  myErrors[xindex]?.cost_types_masters[index]
                                    ?.cost
                                }
                              </div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="form_btn  d-flex mt-3 text-center justify-content-center flex-wrap gap-2">
                <button type="submit" className="btn w-100 p-2 btnSubmit ">
                  Save
                </button>

                <button
                  type="button"
                  onClick={() =>
                    navigate(`/${routesConstants.OVERHEAD_COST_GROUP}`)
                  }
                  className="btn w-100 p-2 btnCancel"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddEditOverheadGroup;
