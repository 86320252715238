import lazy from "../utils/lazy";

export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "../modules/Login")
);

export const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ "../modules/Login/components/ForgotPassword"
  )
);

export const AmazonFees = lazy(() =>
  import(/* webpackChunkName: "ProductsPage" */ "../modules/AmazonFees/pages")
);
export const Products = lazy(() =>
  import(/* webpackChunkName: "ProductsPage" */ "modules/Products/pages")
);
export const AddEditProduct = lazy(() =>
  import("../modules/Products/components/addEditProduct")
);
// export const ViewProduct = lazy(() => import("../modules/ViewProduct"));
