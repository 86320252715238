export const LOGIN = "login";
export const LOGOUT = "logout";
export const USER = "listUsers";
export const VERIFY_SET_PASSWORD_LINK = "verifySetPasswordLink";
export const SET_PASSWORD = "setPassword";
export const FORGOT_PASSWORD = "forgotPassword";
export const GET_USER_PERMISSION = "getUserPermissions";
export const PRODUCT_FEES = "listProductFees";
export const ADD_UPDATE_PRODUCT_FEES = "addUpdateProductFees";
export const DELETE_USER = "deleteUser";
export const BLOCK_UNBLOCK_USER = "blockUnblockUser";

export const ADD_UPDATE_PRODUCT = "addUpdateProduct";
export const LIST_PRODUCTS = "listProduct";
export const AMAZON_PRODUCT_PRICE = "amazoneProductPrice";
export const FEDEX_PRODUCT_PRICE = "FedExProductPrice";
export const DELETE_PRODUCT = "deleteProduct";
export const GET_PRODUCT_DETAILS = "getProductDetails";

/*Admin  */
export const LIST_ADMIN = "listAllAdmin";
export const ADD_UPDATE_ADMIN = "addUpdateAdmin";
export const LIST_USERS = "getAllUsers";

/* Landing Cost */
export const GET_ALL_COUNTRIES = "getAllCountries";
export const ADD_UPDATE_LANDING_COST = "addEditLandingCosts";
export const LIST_ALL_LANDING_COSTS = "listAllLandingCosts";
export const DELETE_LANDING_COSTS = "deleteLandingCosts";

/* Overhead Cost Group */
export const GET_ALL_MASTER_COST_GROUP_TYPES = "getAllMasterCostGroupTypes";
export const LIST_ALL_OVERHEAD_COST_GROUPS = "listAllOverheadCostGroups";
export const ADD_EDIT_OVERHEAD_COST_GROUP = "addEditOverheadCostGroup";
export const DELETE_OVERHEAD_COST_GROUP = "deleteOverheadCostGroup";
export const GET_OVERHEAD_COST_GROUP_DETAIL = "getOverheadCostGroupDetail";

/* Websites */
export const LIST_ALL_WEBSITES = "listAllWebsites";
export const ADD_UPDATE_WEBSITE = "addUpdateWebsite";
export const DELETE_WEBSITE = "deleteWebsite";
export const GET_WEBSITES_PRODUCT_CATEGORIES = "getWebsitesProductCategories";

export const GENERATE_PRODUCT_SELLING_PLANS = "generateProductSellingPlan";
export const GET_PRODUCT_SELLING_PLAN = "getProductSellingPlan";
export const DELETE_PRODUCT_SELLING_PLAN = "deleteProductSellingPlan";
export const LIST_ALL_SELLING_TEMPLATES = "listAllSellingTemplates";
