export const countries = [
  {
    label: "US",
    value: "US",
  },
  {
    label: "India",
    value: "India",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "France",
    value: "France",
  },
];

export const productUnits = [
  {
    label: "CM",
    value: 0,
  },
  {
    label: "Inch",
    value: 1,
  },
];
export const weightUnits = [
  {
    label: "KG",
    value: 0,
  },
  {
    label: "LBS",
    value: 1,
  },
];

export const sizePerPageList = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  {
    label: 15,
    value: 15,
  },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const unitEnum = {
  1: "Pallet",
  2: "Unit",
  3: "Case",
  4: "Cubic FT",
};

export const groupUnits = [
  {
    label: "Pallet",
    value: 1,
  },
  {
    label: "Unit",
    value: 2,
  },
  {
    label: "Case",
    value: 3,
  },
  {
    label: "Cubic FT",
    value: 4,
  },
];
