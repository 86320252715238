export const SET_SELLING_TEMPLATES = "SET_SELLING_TEMPLATES";
export const SET_SELLING_TEMPLATES_SUCCESS = "SET_SELLING_TEMPLATES_SUCCESS";

export const SET_LANDING_COSTS = "SET_LANDING_COSTS";
export const SET_LANDING_COSTS_SUCCESS = "SET_LANDING_COSTS_SUCCESS";

export const SET_OVERHEAD_COST_GROUP = "SET_OVERHEAD_COST_GROUP";
export const SET_OVERHEAD_COST_GROUP_SUCCESS =
  "SET_OVERHEAD_COST_GROUP_SUCCESS";

export const SET_WEBSITES = "SET_WEBSITES";
export const SET_WEBSITES_SUCCESS = "SET_WEBSITES_SUCCESS";

export const SET_SOURCE_DEST = "SET_SOURCE_DEST";
export const SET_SOURCE_DEST_SUCCESS = "SET_SOURCE_DEST_SUCCESS";

export const SET_TEMPLATE_PAGE = "SET_TEMPLATE_PAGE";
export const SET_TEMPLATE_SIZE_PER_PAGE = "SET_TEMPLATE_SIZE_PER_PAGE";
export const SET_TEMPLATE_TOTAL_COUNT = "SET_TEMPLATE_TOTAL_COUNT";

export const SET_TEMPLATE_DETAIL = "SET_TEMPLATE_DETAIL";
export const SET_TEMPLATE_DETAIL_SUCCESS = "SET_TEMPLATE_DETAIL_SUCCESS";

export const SET_SELLING_PLANS = "SET_SELLING_PLANS";
export const SET_SELLING_PLANS_SUCCESS = "SET_SELLING_PLANS_SUCCESS";

export const LIST_SELLING_PLANS = "LIST_SELLING_PLANS";
export const LIST_SELLING_PLANS_SUCCESS = "LIST_SELLING_PLANS_SUCCESS";
