import {
  SET_USERS,
  SET_USERS_ERRORS,
  SET_USERS_SUCCESS,
  SET_USER_PAGE,
  SET_USER_SIZE_PER_PAGE,
  SET_USER_TOTAL_COUNT,
} from "./actionTypes";

const initialState = {
  usersList: null,
  loading: false,
  error: "",
  pageNo: 1,
  sizePerPage: 10,
  count: 0,
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_USERS:
      return {
        ...state,
        loading: payload,
      };
    case SET_USERS_SUCCESS:
      return {
        ...state,
        usersList: payload,
      };
    case SET_USERS_ERRORS:
      return {
        ...state,
        error: payload,
      };
    case SET_USER_PAGE:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_USER_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_USER_TOTAL_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
