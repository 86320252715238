import {
  DELETE_LANDING_COSTS,
  GET_ALL_COUNTRIES,
  LIST_ALL_LANDING_COSTS,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { setGlobalError } from "store/actions";
import {
  SET_COUNTRIES,
  SET_COUNTRIES_ERROR,
  SET_COUNTRIES_SUCCESS,
  SET_LANDING_COSTS,
  SET_LANDING_COSTS_COUNT,
  SET_LANDING_COSTS_PAGE,
  SET_LANDING_COSTS_SIZE_PER_PAGE,
  SET_LANDING_COSTS_SUCCESS,
} from "./actionTypes";

export const fetchCountries = () => {
  return async (dispatch) => {
    try {
      dispatch(setCountries(true));
      const res = await axiosMilliardAdmin.get(GET_ALL_COUNTRIES);
      if (res?.data?.data) {
        dispatch(setCountriesSuccess(res.data.data));
      }
      dispatch(setCountries(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setCountries(false));
      }
    }
  };
};

export const fetchLandingCosts = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setLandingCosts(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_LANDING_COSTS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setLandingCostsSuccess(res.data.data));
        dispatch(setLandingCostsCount(res.data.data.length));
        cb(res.data.data);
      }
      dispatch(setLandingCosts(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setLandingCosts(false));
      }
    }
  };
};

export const deleteLandingCosts = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosMilliardAdmin.post(DELETE_LANDING_COSTS, payload);
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
      }
    }
  };
};

export const setCountries = (data) => ({
  type: SET_COUNTRIES,
  payload: data,
});

export const setCountriesSuccess = (data) => ({
  type: SET_COUNTRIES_SUCCESS,
  payload: data,
});

export const setCountriesError = (data) => ({
  type: SET_COUNTRIES_ERROR,
  payload: data,
});

export const setLandingCosts = (data) => ({
  type: SET_LANDING_COSTS,
  payload: data,
});

export const setLandingCostsSuccess = (data) => ({
  type: SET_LANDING_COSTS_SUCCESS,
  payload: data,
});

export const setLandingCostsPage = (data) => ({
  type: SET_LANDING_COSTS_PAGE,
  payload: data,
});

export const setLandingCostsSizePerPage = (data) => ({
  type: SET_LANDING_COSTS_SIZE_PER_PAGE,
  payload: data,
});

export const setLandingCostsCount = (data) => ({
  type: SET_LANDING_COSTS_COUNT,
  payload: data,
});
