export const SET_ADMINS = "SET_ADMINS";
export const SET_ADMINS_SUCCESS = "SET_ADMINS_SUCCESS";
export const SET_ADMINS_ERRORS = "SET_ADMINS_ERRORS";

export const SET_ADMIN_PAGE = "SET_ADMIN_PAGE";
export const SET_ADMIN_SIZE_PER_PAGE = "SET_ADMIN_SIZE_PER_PAGE";
export const SET_ADMIN_TOTAL_COUNT = "SET_ADMIN_TOTAL_COUNT";

export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USERS_LIST_SUCCESS = "SET_USERS_LIST_SUCCESS";
export const SET_USERS_LIST_ERROR = "SET_USERS_LIST_ERROR";
