import { LIST_ADMIN, LIST_USERS } from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { getGlobalError, setGlobalError } from "store/actions";
import {
  SET_ADMINS,
  SET_ADMINS_ERRORS,
  SET_ADMINS_SUCCESS,
  SET_ADMIN_PAGE,
  SET_ADMIN_SIZE_PER_PAGE,
  SET_ADMIN_TOTAL_COUNT,
  SET_USERS_LIST,
  SET_USERS_LIST_ERROR,
  SET_USERS_LIST_SUCCESS,
} from "./actionTypes";

export const fetchAdmins = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setAdmins(true));
      const res = await axiosMilliardAdmin.post(LIST_ADMIN, payload);
      if (res?.data?.data) {
        dispatch(setAdminSuccess(res.data.data));
        dispatch(setAdminTotalCount(res.data.data.length));
        cb(res.data.data);
      }
      dispatch(setAdmins(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setAdmins(false));
      }
    }
  };
};

export const fetchUsersList = () => {
  return async (dispatch) => {
    try {
      dispatch(setUsersList(true));
      const res = await axiosMilliardAdmin.get(LIST_USERS);
      if (res?.data?.data) {
        dispatch(setUsersListSuccess(res.data.data));
      }
      dispatch(setUsersList(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setUsersList(false));
      }
    }
  };
};

export const setAdmins = (data) => ({
  type: SET_ADMINS,
  payload: data,
});

export const setAdminSuccess = (data) => ({
  type: SET_ADMINS_SUCCESS,
  payload: data,
});

export const setAdminErrors = (data) => ({
  type: SET_ADMINS_ERRORS,
  payload: data,
});

export const setUsersList = (data) => ({
  type: SET_USERS_LIST,
  payload: data,
});

export const setUsersListSuccess = (data) => ({
  type: SET_USERS_LIST_SUCCESS,
  payload: data,
});

export const setUsersListError = (data) => ({
  type: SET_USERS_LIST_ERROR,
  payload: data,
});

export const setAdminPage = (data) => ({
  type: SET_ADMIN_PAGE,
  payload: data,
});

export const setAdminSizePerPage = (data) => ({
  type: SET_ADMIN_SIZE_PER_PAGE,
  payload: data,
});

export const setAdminTotalCount = (data) => ({
  type: SET_ADMIN_TOTAL_COUNT,
  payload: data,
});
