import {
  DELETE_PRODUCT_SELLING_PLAN,
  GENERATE_PRODUCT_SELLING_PLANS,
  GET_PRODUCT_SELLING_PLAN,
  LIST_ALL_SELLING_TEMPLATES,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import {
  SET_SELLING_PLANS,
  SET_SELLING_PLANS_SUCCESS,
  LIST_SELLING_PLANS,
  LIST_SELLING_PLANS_SUCCESS,
  SET_SELLING_TEMPLATES,
  SET_SELLING_TEMPLATES_SUCCESS,
} from "./actionTypes";

export const fetchSellingTemplates = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(setSellingTemplate(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_SELLING_TEMPLATES,
        payload
      );
      if (res?.data?.data) {
        dispatch(setSellingTemplateSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(setSellingTemplate(false));
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
      dispatch(setSellingTemplate(false));
    }
  };
};

export const generateSellingPlan = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setSellingPlan(true));
      const res = await axiosMilliardAdmin.post(
        GENERATE_PRODUCT_SELLING_PLANS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setSellingPlanSuccess(res.data.data));
        cb(res?.data.data);
      }
      dispatch(setSellingPlan(false));
    } catch (err) {
      dispatch(setSellingPlan(false));
    }
  };
};

export const fetchSellingPlans = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(listSellingPlans(true));
      const res = await axiosMilliardAdmin.post(
        GET_PRODUCT_SELLING_PLAN,
        payload
      );
      if (res?.data?.data) {
        dispatch(listSellingPlansSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(listSellingPlans(false));
    } catch (err) {
      dispatch(listSellingPlans(false));
    }
  };
};

export const deleteSellingPlan = (payload, cb) => {
  return async () => {
    try {
      const res = await axiosMilliardAdmin.post(
        DELETE_PRODUCT_SELLING_PLAN,
        payload
      );
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
    }
  };
};

export const setSellingTemplate = (data) => ({
  type: SET_SELLING_TEMPLATES,
  payload: data,
});

export const setSellingTemplateSuccess = (data) => ({
  type: SET_SELLING_TEMPLATES_SUCCESS,
  payload: data,
});

export const setSellingPlan = (data) => ({
  type: SET_SELLING_PLANS,
  payload: data,
});

export const setSellingPlanSuccess = (data) => ({
  type: SET_SELLING_PLANS_SUCCESS,
  payload: data,
});

export const listSellingPlans = (data) => ({
  type: LIST_SELLING_PLANS,
  payload: data,
});

export const listSellingPlansSuccess = (data) => ({
  type: LIST_SELLING_PLANS_SUCCESS,
  payload: data,
});
