import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/common/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants/Table";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";
import blockIcon from "assets/icons/block.png";
import deleteIcon from "assets/icons/delete.png";
import editIcon from "assets/icons/edit.png";
import unblockIcon from "assets/icons/user.png";

import LocalStorage from "services/localStorage";
import {
  blockUser,
  deleteUser,
  fetchAdmins,
  fetchUsersList,
  setAdminPage,
  setAdminSizePerPage,
} from "store/actions";
import { getHiddenRowKeys } from "utils/getHiddenRowKeys";
import { FaSort } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import { Oval } from "react-loader-spinner";

const AdminTable = () => {
  const [isOpenBlock, setIsOpenBlock] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isBlocked, setIsBlocked] = useState();
  const [adminId, setAdminId] = useState();
  const [adminData, setAdminData] = useState();

  const { adminsList, page, sizePerPage, users, loading } = useSelector(
    (state) => state.adminReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUserId = LocalStorage.get("login_user_id");

  const findUsers = (data) => {
    if (data.length > 0) {
      const matchingObjects = data?.map(
        (userId) =>
          users?.find((item) => item.user_id === userId).first_name +
          " " +
          users?.find((item) => item.user_id === userId).last_name +
          ","
      );
      return matchingObjects;
    } else {
      return null;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "center",
      headerStyle: {
        width: "12%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns"
              onClick={() => {
                setAdminId(row.user_id);
                navigate(`/${routesConstants.ADMINS}/edit/${row.user_id}`);
              }}
            >
              <img src={editIcon} alt="edit" title="Edit" />
            </div>
            <div
              className="actionBtns"
              onClick={() => {
                setIsOpenBlock(true);
                setIsBlocked(row.is_blocked);
                setAdminId(row.user_id);
              }}
            >
              {row.is_blocked === 0 ? (
                <img src={blockIcon} alt="block" title="Block" />
              ) : (
                <img src={unblockIcon} alt="block" title="Unblock" />
              )}
            </div>
            <div
              className="actionBtns"
              onClick={() => {
                setIsOpenDelete(true);
                setAdminId(row.user_id);
              }}
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Name",
      headerAlign: "left",
      headerStyle: {
        width: "20%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return <span>{`${row.first_name} ${row.last_name} `}</span>;
      },
    },
    {
      dataField: "email",
      headerAlign: "left",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "25%", // Set the width of the header cell
      },
    },
    {
      dataField: "modules",
      headerAlign: "left",
      text: "Permissions",
      formatter: (cell, row) => {
        return (
          <>
            {row?.user_permission?.is_users === 1 && (
              <span className="moduleSpan">Users</span>
            )}
            {row?.user_permission?.is_products === 1 && (
              <span className="moduleSpan" id={`product${row.user_id}`}>
                <UncontrolledTooltip
                  placement="top"
                  target={`product${row.user_id}`}
                >
                  {findUsers(row.user_permission.selected_users)?.length > 0 &&
                  +row.user_permission.is_all_users_selected === 0
                    ? findUsers(row.user_permission.selected_users)
                    : +row.user_permission.is_all_users_selected === 1
                    ? "All Users"
                    : "0 Users"}
                </UncontrolledTooltip>
                Products (
                {+row.user_permission.is_all_users_selected === 1
                  ? "All Users"
                  : `${row.user_permission.selected_users.length} Users`}
                )
              </span>
            )}
            {row?.user_permission?.is_overhead_cost_group === 1 && (
              <span className="moduleSpan">Overhead Cost Group</span>
            )}
            {row?.user_permission?.is_landing_cost === 1 && (
              <span className="moduleSpan">Landing Cost</span>
            )}
            {row?.user_permission?.is_amazon_fees === 1 && (
              <span className="moduleSpan">Amazon Fees</span>
            )}
            {row?.user_permission?.is_websites === 1 && (
              <span className="moduleSpan">Websites</span>
            )}
          </>
        );
      },
    },
  ]);

  const columnsData = columns?.map((col) => ({
    ...col,
    ...(col.hasOwnProperty("sort")
      ? {
          sortCaret: () => {
            return (
              <span className="ms-2 sort-arrows cursor-pointer">
                <FaSort />
              </span>
            );
          },
        }
      : {}),
  }));

  const { hiddenRows } = getHiddenRowKeys({
    page,
    sizePerPage,
    list: adminsList,
    keyField: "user_id",
  });

  const handleAddAdmin = () => {
    navigate(`/${routesConstants.ADD_ADMIN}`);
  };

  useEffect(() => {
    dispatch(
      fetchAdmins(
        {
          search: "",
        },
        (res) => {
          if (res) {
            setAdminData(res);
          }
        }
      )
    );
    dispatch(fetchUsersList());
  }, [dispatch]);

  const handleClose = () => {
    if (isOpenBlock) {
      setIsOpenBlock(false);
    } else if (isOpenDelete) {
      setIsOpenDelete(false);
    } else {
    }
  };

  const handleBlockAdmin = () => {
    dispatch(
      blockUser(
        {
          user_id: adminId,
          is_blocked: isBlocked === 1 ? 0 : 1,
          login_user_id: loggedInUserId,
        },
        (res) => {
          if (res) {
            setIsOpenBlock(false);
            dispatch(
              fetchAdmins(
                {
                  search: "",
                },
                (res) => {
                  if (res) {
                    setAdminData(res);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const handleDeleteAdmin = () => {
    dispatch(
      deleteUser(adminId, (res) => {
        if (res) {
          setIsOpenDelete(false);
          dispatch(
            fetchAdmins(
              {
                search: "",
              },
              (res) => {
                if (res) {
                  setAdminData(res);
                  dispatch(setAdminPage(1));
                }
              }
            )
          );
        }
      })
    );
  };

  const handlePageChange = (value) => {
    dispatch(setAdminPage(value));
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setAdminSizePerPage(value));
    dispatch(setAdminPage(1));
  };

  const handleFilter = (search) => {
    if (search === "") setAdminData(adminsList);
    else {
      let array = [];
      array = adminsList?.filter(
        (f) =>
          (f.first_name + " " + f.last_name)
            ?.toLowerCase()
            ?.search(search.toLowerCase()) !== -1
      );
      setAdminData(array);
    }
  };

  return (
    <div>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Admins</h4>
          <button className="btnAddAdmin" onClick={handleAddAdmin}>
            Add
          </button>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <input
            type="search"
            className="searchInput"
            placeholder="Search"
            onChange={(e) => handleFilter(e.target.value)}
          />
        </div>

        <div className="mb-2 d-flex flex-column ">
          <div className="flex-grow-1">
            {loading ? (
              <div className="loader-spin">
                <Oval
                  height={40}
                  width={40}
                  color="#464243"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#464243"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-responsive main-table admin_page_table">
                <BootstrapTable
                  noDataIndication={() => (
                    <span className="text-center margin_left_45">
                      No Data Found
                    </span>
                  )}
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"user_id"}
                  hiddenRows={hiddenRows}
                  data={adminData?.length > 0 ? adminData : []}
                  columns={columnsData}
                  hover
                  classes={`table table-hover`}
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div></div>
          {adminData?.length > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+page}
                sizePerPage={sizePerPage}
                totalRecords={adminData?.length}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpenBlock ? isOpenBlock : isOpenDelete ? isOpenDelete : ""}
        handleClose={handleClose}
        cb={
          isOpenBlock
            ? handleBlockAdmin
            : isOpenDelete
            ? handleDeleteAdmin
            : null
        }
        btnText={
          isOpenBlock && +isBlocked === 0
            ? "Block"
            : isOpenBlock && +isBlocked === 1
            ? "Unblock"
            : isOpenDelete
            ? "Delete"
            : ""
        }
        title={
          isOpenBlock && +isBlocked === 0
            ? "Block Admin"
            : isOpenBlock && +isBlocked === 1
            ? "Unblock Admin"
            : isOpenDelete
            ? "Delete Admin"
            : ""
        }
        subTitle={
          isOpenBlock && +isBlocked === 0
            ? "Are you sure want to block this admin?"
            : isOpenBlock && +isBlocked === 1
            ? "Are you sure want to Unblock this admin?"
            : isOpenDelete
            ? "Are you sure want to delete this admin?"
            : ""
        }
      />
    </div>
  );
};

export default AdminTable;
