import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import SiteLogo from "../../../assets/images/SiteLogo.png";
import SimpleDotLoader from "../../../components/loader/SimpleDotLoader";

import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { SET_PASSWORD } from "constants/url";
import { useNavigate, useParams } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";

const initialValues = {
  new_password: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  new_password: Yup.string().required("Enter a password."),
  password: Yup.string()
    .required("Enter a confirm password.")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { token } = useParams();
  const { userLink } = useSelector((state) => state.Auth);

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const res = await axiosMilliardAdmin.post(SET_PASSWORD, {
        password: btoa(values?.password),
        token: token,
        user_id: userLink?.user_id,
      });
      if (res) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate(`/${routesConstants.LOGIN}`);
      }
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const { handleChange, handleBlur, values, errors, handleSubmit, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5 rounded-4">
                    <div className="brand-logo d-flex align-items-end">
                      <img
                        src={SiteLogo}
                        alt="logo"
                        style={{
                          height: "70px",
                          objectFit: "contain",
                          width: "100%",
                          margin: 0,
                          mixBlendMode: "multiply",
                        }}
                      />
                    </div>
                    <div className="forgot-div">
                      <h1>Reset password</h1>
                      <span className="text-secondary">
                        Create your new password to login to your account
                      </span>
                    </div>
                    <Form className="pt-3" onSubmit={handleSubmit}>
                      <Form.Group className="d-flex search-field form-group">
                        <div className="w-100">
                          <Form.Control
                            type="password"
                            name="new_password"
                            placeholder="New Password"
                            size="lg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.new_password}
                            className="h-auto"
                            isInvalid={
                              errors?.new_password && touched?.new_password
                            }
                          />
                          {errors?.new_password && touched?.new_password && (
                            <Form.Control.Feedback type="error">
                              {errors?.new_password}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="d-flex search-field form-group">
                        <div className="w-100">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Confirm Password"
                            size="lg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.password}
                            className="h-auto"
                            isInvalid={errors?.password && touched?.password}
                          />
                          {errors?.password && touched?.password && (
                            <Form.Control.Feedback type="error">
                              {errors?.password}
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Form.Group>
                      <div className="mt-3">
                        <button
                          type="submit"
                          className="btn btn-block auth-form-btn"
                          style={{ backgroundColor: "#c0b6b7" }}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {isSubmitting ? <SimpleDotLoader /> : "SUBMIT"}
                          </div>
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
