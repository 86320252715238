export const SET_WEBSITES = "SET_WEBSITES";
export const SET_WEBSITES_SUCCESS = "SET_WEBSITES_SUCCESS";
export const SET_WEBSITES_ERROR = "SET_WEBSITES_ERROR";

export const SET_WEBSITE_CATEGORIES = "SET_WEBSITE_CATEGORIES";
export const SET_WEBSITE_CATEGORIES_SUCCESS = "SET_WEBSITE_CATEGORIES_SUCCESS";

export const SET_WEBSITES_PAGE = "SET_WEBSITES_PAGE";
export const SET_WEBSITES_SIZE_PER_PAGE = "SET_WEBSITES_SIZE_PER_PAGE";
export const SET_WEBSITES_COUNT = "SET_WEBSITES_COUNT";
