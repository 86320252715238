export const getHiddenRowKeys = ({
  page = 1,
  sizePerPage = 15,
  keyField = "",
  list = [],
}) => {
  const [start, end] = [(page - 1) * sizePerPage, page * sizePerPage];
  const hiddenRows = list
    ?.slice(0, start)
    ?.concat(list?.slice(end))
    ?.map((d) => {
      return d[keyField];
    });
  return {
    hiddenRows,
    start,
    end,
  };
};
