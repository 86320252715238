import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "components/common/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants/Table";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";
import deleteIcon from "assets/icons/delete.png";
import editIcon from "assets/icons/edit.png";

import { getHiddenRowKeys } from "utils/getHiddenRowKeys";
import { FaSort } from "react-icons/fa";
import {
  deleteWebsite,
  fetchWebsites,
  setWebsitesPage,
  setWebsitesSizePerPage,
} from "../store/actions";
import { Oval } from "react-loader-spinner";

const WebsitesTable = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [websiteId, setWebsiteId] = useState();
  const [websiteData, setWebsiteData] = useState();

  const { websites, sizePerPage, page, loading } = useSelector(
    (state) => state.websitesReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "left",
      headerStyle: {
        width: "12%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns"
              onClick={() => {
                setWebsiteId(row.website_id);
                navigate(`/${routesConstants.WEBSITES}/edit/${row.website_id}`);
              }}
            >
              <img src={editIcon} alt="edit" title="Edit" />
            </div>

            <div
              className="actionBtns"
              onClick={() => {
                setIsOpenDelete(true);
                setWebsiteId(row.website_id);
              }}
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        );
      },
    },

    {
      dataField: "website_name",
      headerAlign: "left",
      text: "Website Name",
      sort: true,
    },
    {
      dataField: "country_name",
      headerAlign: "left",
      text: "Country",
      sort: true,
    },
    {
      dataField: "",
      headerAlign: "left",
      text: "Tier 1",
      formatter: (cell, row) => {
        return (
          <span>
            {
              row?.website_products?.find(
                (i) => i.product_category === "Default"
              )?.tier_1_commission
            }
          </span>
        );
      },
    },
    {
      dataField: "",
      headerAlign: "left",
      text: "Tier 2",
      formatter: (cell, row) => {
        return (
          <span>
            {
              row?.website_products?.find(
                (i) => i.product_category === "Default"
              )?.tier_2_commission
            }
          </span>
        );
      },
    },
    {
      dataField: "",
      headerAlign: "left",
      text: "Tier 2 Applied Above (Units)",
      formatter: (cell, row) => {
        return (
          <span>
            {
              row?.website_products?.find(
                (i) => i.product_category === "Default"
              )?.tier_condition
            }
          </span>
        );
      },
    },
    {
      dataField: "",
      headerAlign: "left",
      text: "Total categories",
      formatter: (cell, row) => {
        return <span>{row.website_products?.length}</span>;
      },
    },
  ]);

  const columnsData = columns?.map((col) => ({
    ...col,
    ...(col.hasOwnProperty("sort")
      ? {
          sortCaret: (order) => {
            return (
              <span className="ms-2 sort-arrows cursor-pointer">
                <FaSort />
              </span>
            );
          },
        }
      : {}),
  }));

  const handleAddWebsite = () => {
    navigate(`/${routesConstants.ADD_WEBSITE}`);
  };

  const handleClose = () => {
    setIsOpenDelete(false);
  };

  useEffect(() => {
    dispatch(
      fetchWebsites(
        {
          search: "",
        },
        (res) => {
          if (res) {
            setWebsiteData(res);
          }
        }
      )
    );
  }, [dispatch]);

  const handlePageChange = (value) => {
    dispatch(setWebsitesPage(value));
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setWebsitesSizePerPage(value));
    dispatch(setWebsitesPage(1));
  };

  const handleDeleteWebsites = () => {
    dispatch(
      deleteWebsite(
        {
          website_id: websiteId,
        },
        (res) => {
          if (res) {
            setIsOpenDelete(false);
            dispatch(
              fetchWebsites(
                {
                  search: "",
                },
                (res) => {
                  if (res) {
                    setWebsiteData(res);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const { hiddenRows } = getHiddenRowKeys({
    page,
    sizePerPage,
    list: websites,
    keyField: "website_id",
  });

  const handleFilter = (search) => {
    if (search === "") setWebsiteData(websites);
    else {
      let array = [];
      array = websites?.filter(
        (f) =>
          f.website_name?.toLowerCase()?.search(search.toLowerCase()) !== -1
      );
      setWebsiteData(array);
    }
  };

  return (
    <div>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Websites</h4>
          <button className="btnAddAdmin" onClick={handleAddWebsite}>
            Add
          </button>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <input
            type="search"
            className="searchInput mt-3"
            placeholder="Search"
            onChange={(e) => {
              handleFilter(e.target.value);
            }}
          />
        </div>

        <div className="mb-2 d-flex flex-column ">
          <div className="flex-grow-1">
            {loading ? (
              <div className="loader-spin">
                <Oval
                  height={40}
                  width={40}
                  color="#464243"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#464243"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-responsive main-table">
                <BootstrapTable
                  noDataIndication={() => (
                    <span className="text-center margin_left_45">
                      No Data Found
                    </span>
                  )}
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"website_id"}
                  hiddenRows={hiddenRows}
                  data={websiteData?.length > 0 ? websiteData : []}
                  columns={columnsData}
                  hover
                  classes={`table table-hover`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div></div>
          {websiteData?.length > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+page}
                sizePerPage={sizePerPage}
                totalRecords={websiteData?.length}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpenDelete}
        handleClose={handleClose}
        cb={handleDeleteWebsites}
        btnText={"Delete"}
        title={isOpenDelete}
        subTitle={"Are you sure want to delete this Website?"}
      />
    </div>
  );
};

export default WebsitesTable;
