import { BLOCK_UNBLOCK_USER, DELETE_USER, USER } from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { getGlobalError, setGlobalError } from "store/actions";
import {
  SET_USERS,
  SET_USERS_ERRORS,
  SET_USERS_SUCCESS,
  SET_USER_PAGE,
  SET_USER_SIZE_PER_PAGE,
  SET_USER_TOTAL_COUNT,
} from "./actionTypes";

export const fetchUsers = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setUsers(true));
      const res = await axiosMilliardAdmin.post(USER, payload);
      if (res?.data?.data) {
        dispatch(setUsersSuccess(res.data.data.rows));
        dispatch(setUserTotalCount(res.data.data.count));
      }
      dispatch(setUsers(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setUsers(false));
      }
    }
  };
};

export const deleteUser = (id, cb) => {
  return async () => {
    try {
      const res = await axiosMilliardAdmin.delete(`${id}/${DELETE_USER}`);
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
    }
  };
};

export const blockUser = (payload, cb) => {
  return async () => {
    try {
      const res = await axiosMilliardAdmin.post(BLOCK_UNBLOCK_USER, payload);
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
    }
  };
};

export const setUsers = (data) => ({
  type: SET_USERS,
  payload: data,
});

export const setUsersSuccess = (data) => ({
  type: SET_USERS_SUCCESS,
  payload: data,
});

export const setUsersErrors = (data) => ({
  type: SET_USERS_ERRORS,
  payload: data,
});

export const setUserPage = (data) => ({
  type: SET_USER_PAGE,
  payload: data,
});

export const setUserSizePerPage = (data) => ({
  type: SET_USER_SIZE_PER_PAGE,
  payload: data,
});

export const setUserTotalCount = (data) => ({
  type: SET_USER_TOTAL_COUNT,
  payload: data,
});
