import React, { useEffect, useMemo } from "react";
import { Card } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountries,
  fetchLandingCosts,
  setLandingCostsPage,
} from "../store/actions";
import * as Yup from "yup";
import { axiosMilliardAdmin } from "services/api";
import { ADD_UPDATE_LANDING_COST } from "constants/url";
import { toast } from "react-toastify";
import { routesConstants } from "routes/routesConstants";

const AddEditLandingCost = () => {
  const { landing_costs_id } = useParams();
  const { countries, landingCosts } = useSelector(
    (state) => state.landingCostReducer
  );

  const query = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    landed_cost_id: landing_costs_id ? landing_costs_id : 0,
    landed_cost_name: "",
    source_country: "",
    destination_country: "",
    sea_freight: 0,
    duties: 0,
    tarif: 0,
    drayage: 0,
    unloading: 0,
  };

  const isViewMode = useMemo(() => {
    return query?.pathname.split("/").includes("view") ? true : false;
  }, [query]);

  const allCountries = countries?.map((i) => ({
    ...i,
    label: i.name,
    value: i.country_id,
  }));

  const singleLandingCost = landingCosts?.find(
    (i) => +i.landed_cost_id === +landing_costs_id
  );

  const validationSchema = Yup.object().shape({
    landed_cost_name: Yup.string().required("Landing cost name is required."),
    source_country: Yup.string().required("Please select source country."),
    destination_country: Yup.string().required(
      "Please select destination country."
    ),
    sea_freight: Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1, "Greater than 0")
      .required("Sea Freight is required."),
    duties: Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(0, "Greater than 0")
      .required("Duties are required."),
    tarif: Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(0, "Greater than 0")
      .required("Tarif is required."),
    drayage: Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1, "Greater than 0")
      .required("Drayage is required."),
    unloading: Yup.number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1, "Greater than 0")
      .required("Unloading is required."),
  });

  useEffect(() => {
    /* get landed costs list */
    dispatch(
      fetchLandingCosts(
        {
          search: "",
        },
        null
      )
    );
    /* get countries list */
    dispatch(fetchCountries());
  }, [dispatch]);

  /* Add/Edit Landed cost */
  const onSubmit = async () => {
    const res = await axiosMilliardAdmin.post(ADD_UPDATE_LANDING_COST, values);
    if (res) {
      toast.success(res.data.message);
      navigate(`/${routesConstants.LANDING_COST}`);
      dispatch(setLandingCostsPage(1));
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    errors,
    values,
    validateField,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    /* reset all values with current landed_cost_id data of form at the time of edit */
    if (singleLandingCost) {
      resetForm({
        values: {
          landed_cost_id: singleLandingCost?.landed_cost_id,
          landed_cost_name: singleLandingCost?.landed_cost_name,
          source_country: allCountries?.find(
            (i) => i.label === singleLandingCost?.source_country
          )?.country_id,
          destination_country: allCountries?.find(
            (i) => i.label === singleLandingCost?.destination_country
          )?.country_id,
          sea_freight: singleLandingCost?.sea_freight,
          duties: singleLandingCost?.duties,
          tarif: singleLandingCost?.tarif,
          drayage: singleLandingCost?.drayage,
          unloading: singleLandingCost?.unloading,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleLandingCost, resetForm, landing_costs_id, countries]);

  return (
    <div className="col-12 col-xxl-6">
      <Card className="p-4 mt-5 add_landing_cost_card">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>
            {isViewMode
              ? "View Landed Cost"
              : landing_costs_id
              ? "Edit Landed Cost"
              : "Add Landed Cost"}
          </h4>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className="formData fData">
              <div className="row g-3  mb-3">
                <div className="col-5">
                  <label className="col-form-label">Source Country:</label>
                </div>
                <div className="col-6">
                  <Select
                    options={allCountries}
                    name="source_country"
                    value={allCountries?.filter(
                      (i) => i.value === values?.source_country
                    )}
                    onChange={(e) => {
                      setFieldValue("source_country", e.value).then(() =>
                        validateField("source_country")
                      );
                    }}
                    isDisabled={isViewMode}
                  />
                  {errors?.source_country && (
                    <div className="errorText mt-2 mb-2">
                      {errors.source_country}
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="col-5">
                  <label className="col-form-label">Destination Country:</label>
                </div>
                <div className="col-6">
                  <Select
                    options={allCountries}
                    name="destination_country"
                    value={allCountries?.filter(
                      (i) => i.value === values?.destination_country
                    )}
                    onChange={(e) => {
                      setFieldValue("destination_country", e.value).then(() =>
                        validateField("destination_country")
                      );
                    }}
                    isDisabled={isViewMode}
                  />
                  {errors?.destination_country && (
                    <div className="errorText mt-2 mb-2">
                      {errors.destination_country}
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3 ">
                <div className="col-5">
                  <label className="col-form-label mb-3">
                    Landed Cost Group Name:
                  </label>
                </div>
                <div className="col-6">
                  <div className="input-group mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        id="landed_cost_name"
                        name="landed_cost_name"
                        className="form-control"
                        placeholder="label"
                        onChange={(e) => {
                          setFieldValue(
                            "landed_cost_name",
                            e.target.value
                          ).then(() => validateField("landed_cost_name"));
                        }}
                        value={values?.landed_cost_name}
                      />
                    </div>
                  </div>
                  {errors?.landed_cost_name && (
                    <div className="errorText mt-2 mb-2">
                      {errors.landed_cost_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3 align-items-center">
                <div className="col-5">
                  <label className="col-form-label mb-3">
                    Sea Freight:
                    <br /> (Per 67 CBM)
                  </label>
                </div>
                <div className="col-6">
                  <div
                    className={`input-group ${
                      errors?.sea_freight ? "" : "mb-3"
                    }`}
                  >
                    <span className="input-group-text p_14">$</span>
                    <div className="form-floating">
                      <input
                        type="number"
                        id="sea_freight"
                        name="sea_freight"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("sea_freight", e.target.value).then(
                            () => validateField("sea_freight")
                          );
                        }}
                        value={values?.sea_freight}
                        // min={0}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    </div>
                  </div>
                  {errors?.sea_freight && (
                    <div className="errorText mt-2 mb-2">
                      {errors.sea_freight}
                    </div>
                  )}
                </div>
              </div>

              <div className="row g-3 align-items-center">
                <div className="col-5">
                  <label className="col-form-label">
                    Duties:
                    <br />
                    (Applied on Factory Cost)
                  </label>
                </div>
                <div className="col-6">
                  <div
                    className={`input-group ${
                      errors?.sea_freight ? "" : "mb-3"
                    }`}
                  >
                    <span className="input-group-text">%</span>
                    <div className="form-floating">
                      <input
                        type="number"
                        id="duties"
                        name="duties"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("duties", e.target.value).then(() =>
                            validateField("duties")
                          );
                        }}
                        value={values?.duties}
                        // min="0"
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    </div>
                  </div>
                  {errors?.duties && (
                    <div className="errorText mt-2 mb-2">{errors.duties}</div>
                  )}
                </div>
              </div>

              <div className="row g-3 align-items-center">
                <div className="col-5">
                  <label className="col-form-label">
                    Tarif:
                    <br />
                    (Applied on Factory Cost)
                  </label>
                </div>
                <div className="col-6">
                  <div
                    className={`input-group ${
                      errors?.sea_freight ? "" : "mb-3"
                    }`}
                  >
                    <span className="input-group-text">%</span>
                    <div className="form-floating">
                      <input
                        type="number"
                        id="tarif"
                        className="form-control"
                        name="tarif"
                        value={values?.tarif}
                        onChange={(e) => {
                          setFieldValue("tarif", e.target.value).then(() =>
                            validateField("tarif")
                          );
                        }}
                        // min="0"
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    </div>
                  </div>
                  {errors?.tarif && (
                    <div className="errorText mt-2 mb-2">{errors.tarif}</div>
                  )}
                </div>
              </div>

              <div className="row g-3 align-items-center">
                <div className="col-5">
                  <label className="col-form-label mb-3">
                    Drayage:
                    <br /> (Per 67 CBM)
                  </label>
                </div>
                <div className="col-6">
                  <div
                    className={`input-group ${errors?.drayage ? "" : "mb-3"}`}
                  >
                    <span className="input-group-text p_14">$</span>
                    <div className="form-floating">
                      <input
                        type="number"
                        id="drayage"
                        name="drayage"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("drayage", e.target.value).then(() =>
                            validateField("drayage")
                          );
                        }}
                        value={values?.drayage}
                        // min="0"
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    </div>
                  </div>
                  {errors?.drayage && (
                    <div className="errorText mt-2 mb-2">{errors.drayage}</div>
                  )}
                </div>
              </div>
              <div className="row g-3">
                <div className="col-5">
                  <label className="col-form-label mb-3">
                    Unloading:
                    <br /> (Per 67 CBM)
                  </label>
                </div>
                <div className="col-6">
                  <div
                    className={`input-group ${errors?.unloading ? "" : "mb-3"}`}
                  >
                    <span className="input-group-text p_14">$</span>
                    <div className="form-floating">
                      <input
                        type="number"
                        id="unloading"
                        name="unloading"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("unloading", e.target.value).then(() =>
                            validateField("unloading")
                          );
                        }}
                        value={values?.unloading}
                        // min="0"
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                      />
                    </div>
                  </div>
                  {errors?.unloading && (
                    <div className="errorText mt-2 mb-2">
                      {errors.unloading}
                    </div>
                  )}
                </div>
              </div>

              <div className="form_btn  d-flex mt-3 text-center justify-content-center flex-wrap gap-2">
                <button type="submit" className="btn w-100 p-2 btnSubmit ">
                  {landing_costs_id ? "Update" : "Create"}
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/${routesConstants.LANDING_COST}`)}
                  className="btn w-100 p-2 btnCancel"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddEditLandingCost;
