import { PRODUCT_FEES } from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import { getGlobalError, setGlobalError } from "store/actions";
import {
  SET_PRODUCT_FEES,
  SET_PRODUCT_FEES_ERROR,
  SET_PRODUCT_FEES_SUCCESS,
} from "./actionTypes";

// Get Product Fees
export const getProductDetails = (request) => {
  return async (dispatch) => {
    try {
      dispatch(setProductFees(true));
      const res = await axiosMilliardAdmin.post(PRODUCT_FEES, request);

      if (res?.data?.data) {
        dispatch(setProductFeesSuccess(res.data.data));
      }
      dispatch(setProductFees(false));
    } catch (err) {
      if (err) {
        dispatch(setGlobalError(err));
      } else {
        toast.error(err.response?.data?.Message || err.message);
        dispatch(setProductFees(false));
      }
    }
  };
};

export const setProductFees = (data) => ({
  type: SET_PRODUCT_FEES,
  payload: data,
});

export const setProductFeesSuccess = (data) => ({
  type: SET_PRODUCT_FEES_SUCCESS,
  payload: data,
});

export const setProductFeesError = (data) => ({
  type: SET_PRODUCT_FEES_ERROR,
  payload: data,
});
