import CheckPagePermission from "components/common/CheckPagePermission";
import CheckPasswordPagePermission from "components/common/CheckPasswordPagePermission";
import AddEditAdmin from "modules/Admins/components/addEditAdmin";
import Admin from "modules/Admins/pages";
import AddEditLandingCost from "modules/LandingCost/components/AddEditLandingCost";
import LandingCost from "modules/LandingCost/pages";
import ResetPassword from "modules/Login/components/ResetPassword";
import AddEditOverheadGroup from "modules/OverheadCostGroup/components/addEditOverheadGroup";
import OverheadCostGroup from "modules/OverheadCostGroup/pages";
import AddEditProduct from "modules/Products/components/addEditProduct";
import Products from "modules/Products/pages";
import ManageSellingPlans from "modules/SellingTemplates/components/ManageSellingPlans";
import User from "modules/Users/pages";
import AddEditWebsite from "modules/Websites/components/AddEditWebsite";
import Websites from "modules/Websites/pages";
import { AmazonFees, ForgotPasswordPage, LoginPage } from "./routeImports";
import { routesConstants } from "./routesConstants";

const routesConfig = {
  private: [
    {
      path: routesConstants?.AMAZON_FEES,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AmazonFees }],
    },
    {
      path: routesConstants.USERS,
      Component: CheckPagePermission,
      children: [{ index: true, Component: User }],
    },
    {
      path: routesConstants.ADMINS,
      Component: CheckPagePermission,
      children: [{ index: true, Component: Admin }],
    },
    {
      path: routesConstants.ADD_ADMIN,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditAdmin }],
    },
    {
      path: routesConstants.EDIT_ADMIN,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditAdmin }],
    },
    {
      path: routesConstants.VIEW_ADMIN,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditAdmin }],
    },
    {
      path: routesConstants.PRODUCTS,
      Component: CheckPagePermission,
      children: [{ index: true, Component: Products }],
    },
    {
      path: routesConstants.EDIT_PRODUCT,
      Component: CheckPagePermission,
      children: [
        {
          index: true,
          Component: AddEditProduct,
        },
      ],
    },
    {
      path: routesConstants.VIEW_PRODUCT,
      Component: CheckPagePermission,
      children: [
        {
          index: true,
          Component: AddEditProduct,
        },
      ],
    },
    {
      path: routesConstants.LANDING_COST,
      Component: CheckPagePermission,
      children: [{ index: true, Component: LandingCost }],
    },
    {
      path: routesConstants.ADD_LANDING_COST,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditLandingCost }],
    },
    {
      path: routesConstants.EDIT_LANDING_COST,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditLandingCost }],
    },
    {
      path: routesConstants.VIEW_LANDING_COST,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditLandingCost }],
    },
    {
      path: routesConstants.OVERHEAD_COST_GROUP,
      Component: CheckPagePermission,
      children: [{ index: true, Component: OverheadCostGroup }],
    },
    {
      path: routesConstants.ADD_OVERHEAD_COST_GROUP,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditOverheadGroup }],
    },
    {
      path: routesConstants.EDIT_OVERHEAD_COST_GROUP,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditOverheadGroup }],
    },
    {
      path: routesConstants.VIEW_OVERHEAD_COST_GROUP,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditOverheadGroup }],
    },
    {
      path: routesConstants.WEBSITES,
      Component: CheckPagePermission,
      children: [{ index: true, Component: Websites }],
    },
    {
      path: routesConstants.EDIT_WEBSITE,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditWebsite }],
    },
    {
      path: routesConstants.ADD_WEBSITE,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditWebsite }],
    },
    {
      path: routesConstants.VIEW_WEBSITE,
      Component: CheckPagePermission,
      children: [{ index: true, Component: AddEditWebsite }],
    },
    {
      path: routesConstants.SELLING_PLANS,
      Component: CheckPagePermission,
      children: [{ index: true, Component: ManageSellingPlans }],
    },
  ],
  public: [
    {
      path: routesConstants.LOGIN,
      Component: LoginPage,
    },
    {
      path: routesConstants.SET_PASSWORD,
      Component: CheckPasswordPagePermission,
      children: [{ index: true, Component: ResetPassword }],
    },
    {
      path: routesConstants.FORGOT_PASSWORD,
      Component: ForgotPasswordPage,
    },
  ],
};

export default routesConfig;
