import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "components/common/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants/Table";
import { useNavigate } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";
import deleteIcon from "assets/icons/delete.png";
import editIcon from "assets/icons/edit.png";
import {
  deleteOverheadCosts,
  fetchOverheadCostGroups,
  setOverheadCostPage,
  setOverheadCostSizePerPage,
} from "../store/actions";
import { FaSort } from "react-icons/fa";
import { getHiddenRowKeys } from "utils/getHiddenRowKeys";
import Select from "react-select";
import { unitEnum } from "constants";
import { Oval } from "react-loader-spinner";

const OverheadCostGroupTable = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [overheadGroupId, setOverheadGroupId] = useState();
  const [overheadData, setOverheadData] = useState();

  const { overheadCostGroups, sizePerPage, page, loading } = useSelector(
    (state) => state.overheadCostReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalGroups = overheadCostGroups?.map((i) => ({
    ...i,
    label: i.group_title,
    value: i.overhead_group_id,
  }));

  const findDuplicateArrays = (array) => {
    const seen = {};
    return array?.reduce((result, item) => {
      const key = item.cost_types_master.cost_group_id;
      if (!seen[key]) {
        seen[key] = true;
        result.push(item);
      }
      return result;
    }, []);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subColumns = useMemo(() => [
    {
      dataField: "",
      text: "Cost Group",
      headerAlign: "left",
      headerStyle: {
        width: "30%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return (
          <div>{row.cost_types_master.cost_group_master.cost_group_title}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Cost Type",
      headerAlign: "left",
      headerStyle: {
        width: "30%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return <div>{row.cost_types_master.cost_type}</div>;
      },
    },
    {
      dataField: "",
      text: "Amount",
      headerAlign: "left",
      headerStyle: {
        width: "20%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return <div>{`$${row.cost}`}</div>;
      },
    },
    {
      dataField: "",
      text: "Per Unit",
      headerAlign: "left",
      headerStyle: {
        width: "20%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return <div>{unitEnum[row.cost_unit]}</div>;
      },
    },
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "left",
      headerStyle: {
        width: "12%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return duplicates?.find((i) => i.id === row.id)?.id === row.id ? (
          <div className="d-flex justify-content-evenly">
            <div
              className="actionBtns"
              onClick={() => {
                setOverheadGroupId(row.overhead_group_id);
                navigate(
                  `/${routesConstants.OVERHEAD_COST_GROUP}/edit/${row.overhead_group_id}`
                );
              }}
            >
              <img src={editIcon} alt="edit" title="Edit" />
            </div>

            <div
              className="actionBtns"
              onClick={() => {
                setIsOpenDelete(true);
                setOverheadGroupId(row.overhead_group_id);
              }}
            >
              <img src={deleteIcon} alt="delete" title="Delete" />
            </div>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      dataField: "group_title",
      text: "Group Name",
      headerAlign: "left",
      sort: true,
      formatter: (cell, row) => {
        return duplicates?.find((i) => i.id === row.id)?.id === row.id ? (
          <span>{cell}</span>
        ) : (
          ""
        );
      },
    },
    {
      dataField: "",
      headerAlign: "left",
      text: "",
      headerFormatter: () => (
        <div className="row">
          <div className="col-4">Cost Group</div>
          <div className="col-5">Cost Type</div>
          <div className="col-1 mr_5">Amount</div>
          <div className="col-1">Per Unit</div>
        </div>
      ),
      // sort: true,

      formatter: (cell, row) => {
        const duplicates1 = findDuplicateArrays(
          row.overhead_cost_groups_details
        );
        return (
          <div>
            <BootstrapTable
              noDataIndication={() => (
                <span className="text-center margin_left_45">
                  No Data Found
                </span>
              )}
              responsive
              bordered={false}
              striped={false}
              keyField={"title"}
              data={
                row.overhead_cost_groups_details?.length > 0
                  ? row.overhead_cost_groups_details
                  : []
              }
              columns={subColumns}
              hover
              classes={`table table-hover`}
              headerClasses="hidden-header-row"
              duplicates1={duplicates1}
            />
          </div>
        );
      },
    },
  ]);

  const columnsData = columns?.map((col) => ({
    ...col,
    ...(col.hasOwnProperty("sort")
      ? {
          sortCaret: (order) => {
            return (
              <span className="ms-2 sort-arrows cursor-pointer">
                <FaSort />
              </span>
            );
          },
        }
      : {}),
  }));

  const handleAddOverheadCost = () => {
    navigate(`/${routesConstants.ADD_OVERHEAD_COST_GROUP}`);
  };

  const handleClose = () => {
    setIsOpenDelete(false);
  };

  useEffect(() => {
    dispatch(
      fetchOverheadCostGroups(
        {
          search: "",
        },
        (res) => {
          if (res) {
            setOverheadData(res);
          }
        }
      )
    );
  }, [dispatch]);

  const handlePageChange = (value) => {
    dispatch(setOverheadCostPage(value));
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setOverheadCostSizePerPage(value));
    dispatch(setOverheadCostPage(1));
  };

  const handleDeleteOverheadCosts = () => {
    dispatch(
      deleteOverheadCosts(
        {
          overhead_group_id: overheadGroupId,
        },
        (res) => {
          if (res) {
            setIsOpenDelete(false);
            dispatch(
              fetchOverheadCostGroups(
                {
                  search: "",
                },
                (res) => {
                  if (res) {
                    setOverheadData(res);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const { hiddenRows } = getHiddenRowKeys({
    page,
    sizePerPage,
    list: overheadData,
    keyField: "overhead_group_id",
  });

  const duplicates = overheadData?.reduce((acc, obj, index) => {
    const duplicates = overheadData
      ?.sort((a, b) => a.group_title - b.group_title)
      .filter((item, i) => i !== index && item.group_title === obj.group_title);

    if (
      duplicates.length > 0 &&
      !acc.some((dup) => dup.group_title === obj.group_title)
    ) {
      return acc.concat([obj]);
    }
    return acc;
  }, []);

  const handleFilter = (e) => {
    if (e === "" || e === null) setOverheadData(overheadCostGroups);
    else {
      let array = [];
      array = overheadCostGroups?.filter(
        (f) => f.overhead_group_id === e.value
      );
      setOverheadData(array);
    }
  };

  return (
    <div>
      <Card className="p-4 mt-5">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Overhead Cost Groups</h4>
          <button className="btnAddAdmin" onClick={handleAddOverheadCost}>
            Add
          </button>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>

          <Select
            options={totalGroups}
            name="overhead_cost.title"
            placeholder="Filter by Group"
            onChange={(e) => handleFilter(e)}
            isClearable
          />
        </div>

        <div className="mb-2 d-flex flex-column ">
          <div className="flex-grow-1">
            {loading ? (
              <div className="loader-spin">
                <Oval
                  height={40}
                  width={40}
                  color="#464243"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#464243"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <div className="table-responsive main-table">
                <BootstrapTable
                  noDataIndication={() => (
                    <span className="text-center margin_left_45">
                      No Data Found
                    </span>
                  )}
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"title"}
                  data={overheadData?.length > 0 ? overheadData : []}
                  columns={columnsData}
                  hiddenRows={hiddenRows}
                  hover
                  classes={`table table-hover`}
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div></div>
          {overheadData?.length > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+page}
                sizePerPage={sizePerPage}
                totalRecords={overheadData?.length}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpenDelete}
        handleClose={handleClose}
        cb={handleDeleteOverheadCosts}
        btnText={"Delete"}
        title={isOpenDelete}
        subTitle={"Are you sure want to delete this Overhead Cost Group?"}
      />
    </div>
  );
};

export default OverheadCostGroupTable;
