import {
  SET_ADMINS,
  SET_ADMINS_ERRORS,
  SET_ADMINS_SUCCESS,
  SET_ADMIN_PAGE,
  SET_ADMIN_SIZE_PER_PAGE,
  SET_ADMIN_TOTAL_COUNT,
  SET_USERS_LIST,
  SET_USERS_LIST_ERROR,
  SET_USERS_LIST_SUCCESS,
} from "./actionTypes";

const initialState = {
  adminsList: null,
  loading: false,
  error: "",
  page: 1,
  sizePerPage: 5,
  count: 0,
  users: null,
};

const adminReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ADMINS:
      return {
        ...state,
        loading: payload,
      };
    case SET_ADMINS_SUCCESS:
      return {
        ...state,
        adminsList: payload,
      };
    case SET_ADMINS_ERRORS:
      return {
        ...state,
        error: payload,
      };

    case SET_USERS_LIST:
      return {
        ...state,
        loading: payload,
      };
    case SET_USERS_LIST_SUCCESS:
      return {
        ...state,
        users: payload,
      };
    case SET_USERS_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case SET_ADMIN_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_ADMIN_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_ADMIN_TOTAL_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
