export const SET_AUTH = "SET_AUTH";
export const SET_USER = "SET_USER";

export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PERMISSIONS_SUCCESS = "SET_PERMISSIONS_SUCCESS";
export const SET_PERMISSIONS_ERROR = "SET_PERMISSIONS_ERROR";

export const SET_PAGE_ERROR = "SET_PAGE_ERROR";

export const SET_VERIFY_LINK = "SET_VERIFY_LINK";
export const SET_VERIFY_LINK_SUCCESS = "SET_VERIFY_LINK_SUCCESS";
