import { combineReducers } from "redux";

import Auth from "./Auth/reducer";
import productReducer from "modules/Products/store/reducer";
import userReducer from "modules/Users/store/reducer";
import adminReducer from "modules/Admins/store/reducer";
import feesReducer from "modules/AmazonFees/store/reducer";
import landingCostReducer from "modules/LandingCost/store/reducer";
import overheadCostReducer from "modules/OverheadCostGroup/store/reducer";
import websitesReducer from "modules/Websites/store/reducer";
import sellingTemplateReducer from "modules/SellingTemplates/store/reducer";

const rootReducer = combineReducers({
  Auth,
  productReducer,
  userReducer,
  adminReducer,
  feesReducer,
  landingCostReducer,
  overheadCostReducer,
  websitesReducer,
sellingTemplateReducer,
});

export default rootReducer;
